import { FadeUp, Footer, Header } from "@/components";
import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

const Root = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <FadeUp>
      <Header />
      {/* Margin should match sticky header size */}
      <Box as="main" mt="4.5rem">
        <Outlet />
      </Box>
      <Footer />
    </FadeUp>
  );
};

export default Root;
