import { Box, Flex, keyframes, Slide } from "@chakra-ui/react";
import RouterLink from "../common/RouterLink";

interface Props {
  routes: { label: string; href: string }[];
  isOpen: boolean;
  onToggle: () => void;
}

const MobileHeader: React.FC<Props> = ({ routes, isOpen, onToggle }) => {
  const fade = keyframes`  
  from {opacity: 0}   
  to {opacity: 0.5} 
`;

  const animation = `${fade} 0.5s ease-in forwards`;

  return (
    <Slide
      direction="right"
      in={isOpen}
      style={{
        zIndex: 10,
        maxWidth: "25rem",
        height: "calc(100vh - 4rem)",
        right: "0",
        top: "4rem",
      }}
    >
      <Box h="full" w="full" bgColor="background" p={4} py={6}>
        <Flex as="ul" direction="column" gap={8}>
          {routes.map((route, index) => (
            <Box as="li" listStyleType="none" key={index}>
              <RouterLink to={route.href} color="heading" fontWeight="medium">
                {route.label}
              </RouterLink>
            </Box>
          ))}
        </Flex>
      </Box>
      <Box
        animation={isOpen ? animation : undefined}
        onClick={onToggle}
        position="fixed"
        opacity={0}
        zIndex={5}
        w="100%"
        h="full"
        left={isOpen ? "-100%" : 0}
        top={0}
        bgColor="black"
      />
    </Slide>
  );
};

export default MobileHeader;
