import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";

interface Props extends AccordionItemProps {
  question: string;
  answer: string;
}

const FAQAccordion = ({ question, answer, ...props }: Props) => {
  return (
    <AccordionItem {...props}>
      {({ isExpanded }) => (
        <>
          <h3>
            <AccordionButton
              border="1px solid"
              borderColor="secondary.500"
              bgColor={isExpanded ? "secondary.500" : "inherit"}
              color={isExpanded ? "white" : "secondary.500"}
              _hover={{ bgColor: isExpanded ? "secondary.600" : "gray.100" }}
              borderRadius="md"
            >
              <Text
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="medium"
                fontSize={{ base: "md", md: "lg" }}
              >
                {question}
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel
            pb={4}
            fontSize={{ base: "sm", md: "md" }}
            bgColor="white"
          >
            <span dangerouslySetInnerHTML={{ __html: answer }} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default FAQAccordion;
