import { Container } from "@/components";
import useGlobalStore from "@/store";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import FlightForm from "../home/FlightForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EditBookingModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const booking = useGlobalStore(state => state.booking);
  const defaultValues = {
    ...booking,
    date: booking?.date ? new Date(booking.date) : undefined,
  };

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="container.xl">
        <ModalHeader color="heading" textAlign="center">
          Edit Search
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8} px={{ base: 4, sm: 6 }}>
          <Container p={0}>
            <FlightForm defaultValues={defaultValues} callback={onClose} />
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditBookingModal;
