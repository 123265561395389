import { Container, FadeIn, FadeUp, GrowUp } from "@/components";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

interface Props {}

const IMAGES = [
  "mastercard.svg",
  "visa.svg",
  "american-express.svg",
  "diners-club.svg",
  "jcb.svg",
  "dotpay.svg",
];

const PaymentOptions: React.FC<Props> = () => {
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(ref.current?.clientHeight ?? 0);
  }, []);

  return (
    <Grid
      minH="30rem"
      placeItems="center"
      py="4rem"
      px={4}
      position="relative"
      _before={{
        content: "''",
        inset: "0",
        position: "absolute",
        h: "50%",
        bgColor: "backgroundAlt",
        zIndex: -1,
      }}
      _after={{
        content: "''",
        inset: "0",
        position: "absolute",
        bgColor: "secondary.600",
        zIndex: -1,
        my: "4rem",
        w: { md: "50%" },
      }}
    >
      <Container
        px={0}
        bgColor="secondary.600"
        py="7rem"
        pr={{ md: "6rem" }}
        borderRadius="2xl"
      >
        <Grid
          templateColumns={{ md: "1fr 1.5fr" }}
          gap={{ base: "4rem", md: 4 }}
        >
          <Flex direction="column" gap={7}>
            <FadeUp>
              <Heading color="white">Payment options</Heading>
            </FadeUp>
            <Flex align="end" gap={4} h={`${height}px`}>
              <GrowUp>
                <Box h="full" minW={1} bgColor="primary.main" />
              </GrowUp>
              <FadeUp>
                <Text color="rgb(255, 255, 255, 0.9)" ref={ref}>
                  We can easily book and securely pay for your flight at Huriya
                  Air. The methods of payment available and much more
                  information can be found in the following sections.
                </Text>
              </FadeUp>
            </Flex>
            <FadeUp>
              <Button w="max-content" colorScheme="primary">
                Learn more
              </Button>
            </FadeUp>
          </Flex>
          <Grid
            templateColumns={{ base: "1fr 1fr", sm: "1fr 1fr 1fr" }}
            gap="4rem"
          >
            {IMAGES.map((image, index) => (
              <FadeIn key={index}>
                <Image
                  src={`/images/${image}`}
                  w="full"
                  h="5rem"
                  objectFit="contain"
                />
              </FadeIn>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default PaymentOptions;
