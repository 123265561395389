import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { NavLink, LinkProps as RouterLinkProps } from "react-router-dom";

interface Props extends Omit<LinkProps, "as">, Omit<RouterLinkProps, "color"> {}

const RouterLink: React.FC<Props> = props => {
  return (
    <ChakraLink as={NavLink} {...props}>
      {props.children}
    </ChakraLink>
  );
};

export default RouterLink;
