import { MotionBox } from "@/components";
import { Place } from "@/types";
import {
  Card,
  CardBody,
  CardProps,
  Heading,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { useReducedMotion, Variants } from "framer-motion";
import { useState } from "react";
import slugify from "slugify";

interface Props extends CardProps {
  place: Place;
}

const DestinationCard: React.FC<Props> = ({ place, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const shouldReduceMotion = useReducedMotion();

  const variants: Variants = {
    visible: { opacity: 1 },
    hidden: { opacity: shouldReduceMotion ? 1 : 0 },
  };

  return (
    <LinkBox>
      <Card
        role="group"
        w="full"
        h={{ base: "180px", md: "300px" }}
        position="relative"
        bgColor={!isHovered ? "rgba(0, 0, 0, 0.4)" : "transparent"}
        overflow="hidden"
        transition="all ease-in 300ms"
        _before={{
          content: "''",
          position: "absolute",
          inset: "0",
          zIndex: -1,
          bgImage: place.image,
          bgPos: "center",
          bgSize: !isHovered ? "110%" : "100%",
          bgRepeat: "no-repeat",
          transition: "all ease-in 300ms",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchEnd={() => setIsHovered(false)}
        {...props}
      >
        <CardBody display="flex" alignItems="flex-end">
          <MotionBox
            initial="hidden"
            variants={variants}
            animate={!isHovered ? "visible" : "hidden"}
          >
            <Heading as="h2" size="lg" color="primary.main">
              <LinkOverlay
                href={`/destinations/${slugify(place.name, { lower: true })}`}
              >
                {place.name}
              </LinkOverlay>
            </Heading>
          </MotionBox>
        </CardBody>
      </Card>
    </LinkBox>
  );
};

export default DestinationCard;
