import { BoxProps } from "@chakra-ui/react";
import { Variants, useInView, useReducedMotion } from "framer-motion";
import { useRef } from "react";
import MotionBox from "./MotionBox";

interface Props extends BoxProps {
  custom?: any;
  delay?: number;
}

const FadeUp: React.FC<Props> = ({
  delay = 0.2,
  custom,
  children,
  ...props
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const shouldReduceMotion = useReducedMotion();

  const variants: Variants = {
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: (i ?? 0) * delay,
      },
    }),
    hidden: {
      opacity: shouldReduceMotion ? 1 : 0,
      y: shouldReduceMotion ? 0 : 50,
    },
  };

  return (
    <MotionBox
      custom={custom}
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      initial="hidden"
      exit="hidden"
      ref={ref}
      {...props}
    >
      {children}
    </MotionBox>
  );
};

export default FadeUp;
