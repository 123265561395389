import { PLACES } from "@/constants";
import useGlobalStore from "@/store";
import { Button, Divider, Flex, FlexProps } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { BsFillPersonFill } from "react-icons/bs";
import { FaCalendar, FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import { z } from "zod";
import FlightDate from "./FlightDate";
import PassengerInput from "./PassengerInput";
import PlaceInput from "./PlaceInput";

const placeSchema = z.object({
  name: z.string().min(1),
  code: z.string().min(1),
  country: z.string().min(1),
});

const passengersSchema = z.object({
  adult: z.number().min(1),
  child: z.number().min(0),
  infant: z.number().min(0),
});

export const flightSchema = z.object({
  departureAirport: placeSchema,
  arrivalAirport: placeSchema,
  date: z.date(),
  passengers: passengersSchema,
});

const DEFAULT_VALUES: Partial<FlightSchema> = {
  passengers: { adult: 1, child: 0, infant: 0 },
};

export type FlightSchema = z.infer<typeof flightSchema>;

interface Props extends FlexProps {
  defaultValues?: Partial<FlightSchema>;
  callback?: () => void;
}

const FlightForm: React.FC<Props> = props => {
  const { defaultValues = DEFAULT_VALUES, callback, ...rest } = props;
  const setBooking = useGlobalStore(state => state.setBooking);

  const hookform = useForm<FlightSchema>({
    resolver: zodResolver(flightSchema),
    defaultValues,
  });

  const hasErrors = Object.values(hookform.formState.errors).length > 0;
  const arrivalCode = hookform.watch("arrivalAirport.code");
  const departureCode = hookform.watch("departureAirport.code");

  const departureCities = useMemo(
    () => PLACES.filter(place => place.code !== arrivalCode),
    [arrivalCode]
  );

  const arrivalCities = useMemo(
    () => PLACES.filter(place => place.code !== departureCode),
    [departureCode]
  );

  function onSubmit(values: FlightSchema) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        setBooking(values);
        if (callback) callback();
        resolve();
      }, 1000);
    });
  }

  return (
    <Flex
      as="form"
      onSubmit={hookform.handleSubmit(onSubmit)}
      justify="end"
      wrap="wrap"
      gap={{ base: 4, md: 8 }}
      h={{ lg: "6.5rem" }}
      boxShadow="lg"
      p={{ base: 4, md: 6 }}
      bgColor="white"
      borderRadius="md"
      {...rest}
    >
      <PlaceInput
        name="departureAirport"
        hookform={hookform}
        icon={FaPlaneDeparture}
        placeholder="Departure Airport"
        initialValue={defaultValues.departureAirport}
        data={departureCities}
      />
      <Divider
        display={{ base: "none", sm: "block" }}
        minH="3.5rem"
        borderColor="grey"
        orientation="vertical"
      />
      <PlaceInput
        name="arrivalAirport"
        hookform={hookform}
        icon={FaPlaneArrival}
        placeholder="Arrival Airport"
        initialValue={defaultValues.arrivalAirport}
        data={arrivalCities}
      />
      <Divider
        display={{ base: "none", lg: "block" }}
        minH="3.5rem"
        borderColor="grey"
        orientation="vertical"
      />
      <FlightDate
        icon={FaCalendar}
        name="date"
        hookform={hookform}
        placeholder="Date"
      />
      <Divider
        display={{ base: "none", sm: "block" }}
        minH="3.5rem"
        borderColor="grey"
        orientation="vertical"
      />
      <PassengerInput
        name="passengers"
        icon={BsFillPersonFill}
        hookform={hookform}
      />
      <Button
        type="submit"
        isLoading={hookform.formState.isSubmitting}
        isDisabled={hasErrors}
        h="full"
        w={{ base: "full", sm: "auto" }}
        justifySelf={{ base: "end", lg: "auto" }}
        colorScheme="primary"
        py={4}
        px={5}
      >
        Search Your Flight
      </Button>
    </Flex>
  );
};

export default FlightForm;
