import { FormInput, FormSelect } from "@/components";
import { Box, Flex, Grid, Heading } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface Props {}

const validationSchema = z.object({
  title: z.string().min(1, { message: "Title is required" }),
  firstName: z.string().min(1, { message: "First Name is required" }),
  lastName: z.string().min(1, { message: "Last Name is required" }),
  dateOfBirth: z.date({ required_error: "Date of birth is required" }),
});

type ValidationSchema = z.infer<typeof validationSchema>;

const PassengerDetails: React.FC<Props> = ({ ...props }) => {
  const hookform = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: { title: "MR" },
  });

  function onSubmit(values: ValidationSchema) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        resolve();
      }, 1000);
    });
  }

  return (
    <Box as="form" onSubmit={hookform.handleSubmit(onSubmit)} mb={10}>
      <Heading as="h2" size="lg" color="heading" mb={6}>
        Passenger details
      </Heading>
      <Box bgColor="white" rounded="xl" p={6} shadow="sm">
        <Grid templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr" }} gap={4}>
          <Flex gap={{ base: 3, sm: 4 }}>
            <Box flex="0.5">
              <FormSelect
                isRequired
                hookform={hookform}
                label="Title"
                name="title"
              >
                <option value="MR">MR</option>
                <option value="MRS">MRS</option>
                <option value="MS">MS</option>
              </FormSelect>
            </Box>
            <Box flex="1">
              <FormInput
                isRequired
                hookform={hookform}
                label="First Name"
                name="firstName"
                placeholder="First Name"
              />
            </Box>
          </Flex>
          <FormInput
            isRequired
            hookform={hookform}
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
          />
          <FormInput
            type="date"
            isRequired
            hookform={hookform}
            label="Date of birth"
            name="dateOfBirth"
            placeholder="Date of birth"
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default PassengerDetails;
