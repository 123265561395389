import { Container, RouterLink, SEO } from "@/components";
import useGlobalStore from "@/store";
import { Button, Flex } from "@chakra-ui/react";
import ContactInformation from "./ContactInformation";
import PassengerDetails from "./PassengerDetails";

interface Props {}

const Passengers: React.FC<Props> = () => {
  const { setStep, step } = useGlobalStore();
  const handleNextStep = () => setStep(step + 1);

  return (
    <>
      <SEO title="Passengers" />
      <Container py={10}>
        <PassengerDetails />
        <ContactInformation />
        <Flex justify="end" mt={10}>
          <RouterLink
            to="/booking/services"
            onClick={handleNextStep}
            w={{ base: "full", sm: "auto" }}
          >
            <Button
              px="4rem"
              py="2rem"
              colorScheme="primary"
              size="lg"
              minH="3rem"
              maxH="4rem"
              w="full"
            >
              Continue
            </Button>
          </RouterLink>
        </Flex>
      </Container>
    </>
  );
};

export default Passengers;
