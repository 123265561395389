import { Container, RouterLink, SEO } from "@/components";
import useGlobalStore from "@/store";
import { Avatar, Box, Button, Flex, Heading, Text } from "@chakra-ui/react";

interface Props {}

const SeatMap: React.FC<Props> = () => {
  const { setStep, step } = useGlobalStore();
  const handleNextStep = () => setStep(step + 1);

  return (
    <>
      <SEO title="Seat Map" />
      <Container py={10}>
        <Box mb={10}>
          <Heading as="h2" size="lg" color="heading" mb={2}>
            Select your seat(s)
          </Heading>
          <Text>Choose a preferred seat for your travel</Text>
        </Box>
        <Box w={{ md: "40%" }}>
          <Heading as="h3" size="md" color="heading" mb={4}>
            Customer list
          </Heading>
          <Flex
            justify="space-between"
            align="center"
            overflow="hidden"
            borderRadius="xl"
            position="relative"
            _before={{
              content: "''",
              position: "absolute",
              inset: 0,
              w: 2,
              bgColor: "heading",
            }}
            bgColor="white"
            py={6}
            px={10}
          >
            <Flex gap={4} align="center">
              <Avatar name="Ignatius James" size="sm" />
              <Box>
                <Text fontSize="sm">Adult 1</Text>
                <Text>Ignatius James</Text>
              </Box>
            </Flex>
            <Text fontSize="sm">Select seat</Text>
          </Flex>
        </Box>
        <Flex justify="end" mt={10}>
          <RouterLink
            to="/booking/payment"
            onClick={handleNextStep}
            w={{ base: "full", sm: "auto" }}
          >
            <Button
              px="4rem"
              py="2rem"
              colorScheme="primary"
              size="lg"
              minH="3rem"
              maxH="4rem"
              w="full"
            >
              Skip step
            </Button>
          </RouterLink>
        </Flex>
      </Container>
    </>
  );
};

export default SeatMap;
