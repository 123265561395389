import useScroll from "@/hooks/useScroll";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { HiOutlineMenu, HiX } from "react-icons/hi";
import AvatarMenu from "../common/AvatarMenu";
import Container from "../common/Container";
import RouterLink from "../common/RouterLink";
import MobileHeader from "./MobileHeader";

interface Props {}

const ROUTES = [
  { label: "Book & manage", href: "/book-and-manage" },
  { label: "Prepare for travel", href: "/prepare-for-travel" },
  { label: "During your trip", href: "/during-your-trip" },
  { label: "Discover", href: "/discover" },
];

const Header: React.FC<Props> = () => {
  const { onToggle, isOpen } = useDisclosure();
  const position = useScroll();
  const [isLagerThan48] = useMediaQuery("(min-width: 48em)");
  const boxShadow =
    position > 100 ? "0px 6px 12px rgba(8, 75, 109, 0.06)" : undefined;

  return (
    <Box
      as="header"
      pos="fixed"
      zIndex="sticky"
      bgColor="background"
      w="full"
      top={0}
      left={0}
      py={4}
      boxShadow={boxShadow}
    >
      <Container>
        <Flex align="center" justify="space-between" as="nav" gap={6}>
          <RouterLink to="/">
            <Image src="/images/logo.png" h={{ base: 8, sm: 10 }} />
          </RouterLink>
          <Flex as="ul" gap={8} display={{ base: "none", md: "flex" }}>
            {ROUTES.map((route, index) => (
              <Box as="li" listStyleType="none" key={index}>
                <RouterLink to={route.href} color="heading" fontWeight="medium">
                  {route.label}
                </RouterLink>
              </Box>
            ))}
          </Flex>
          <Flex align="center" gap={2}>
            <RouterLink to="/destinations">
              <Button
                size={{ base: "sm", md: "md" }}
                colorScheme="secondary"
                fontWeight="medium"
              >
                Unscheduled flights
              </Button>
            </RouterLink>
            {/* <AvatarMenu /> */}
            <IconButton
              display={{ base: "inline-flex", md: "none" }}
              aria-label="Toggle menu"
              variant="ghost"
              onClick={onToggle}
              icon={<Icon as={isOpen ? HiX : HiOutlineMenu} boxSize={5} />}
            />
          </Flex>
        </Flex>
        {!isLagerThan48 && (
          <MobileHeader routes={ROUTES} isOpen={isOpen} onToggle={onToggle} />
        )}
      </Container>
    </Box>
  );
};

export default Header;
