import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import {
  FaPlaneDeparture,
  FaSuitcase,
  FaSuitcaseRolling,
} from "react-icons/fa";
import { MdAirlineSeatReclineExtra } from "react-icons/md";

interface Props {
  title: string;
  price: string;
  perks: string[];
}

const BookingCard: React.FC<Props> = ({ title, price, perks }) => {
  return (
    <Card boxShadow="lg" borderRadius="2xl" overflow="hidden" size="sm">
      <CardHeader bgColor="primary.500">
        <Heading
          as="h6"
          textAlign="center"
          size="lg"
          fontWeight="normal"
          color="white"
        >
          {title}
        </Heading>
      </CardHeader>
      <CardBody>
        <Flex direction="column" gap={6} h="full">
          <Flex direction="column" gap={3} color="body" flex="1 1 100%">
            {perks.map((perk, index) => (
              <Flex key={index} gap={2} align="center" px={6}>
                <Icon
                  color="secondary.main"
                  as={
                    index === 0
                      ? FaPlaneDeparture
                      : index === 1
                      ? FaSuitcaseRolling
                      : index === 2
                      ? FaSuitcase
                      : MdAirlineSeatReclineExtra
                  }
                />
                <Text>{perk}</Text>
              </Flex>
            ))}
          </Flex>
          <Button size="lg" minH="3rem">
            {price}
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default BookingCard;
