import { FormInput, FormPhone, FormSelect } from "@/components";
import { Box, Flex, Grid, Heading } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface Props {}

const validationSchema = z.object({
  title: z.string().min(1, { message: "Title is required" }),
  firstName: z.string().min(1, { message: "First Name is required" }),
  lastName: z.string().min(1, { message: "Last Name is required" }),
  phone: z.string().min(1, { message: "Phone is required" }),
  email: z.string().email({ message: "Email is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  city: z.string().min(1, { message: "City is required" }),
  address: z.string().min(1, { message: "Address is required" }),
});

type ValidationSchema = z.infer<typeof validationSchema>;

const ContactInformation: React.FC<Props> = ({ ...props }) => {
  const hookform = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: { title: "MR" },
  });

  function onSubmit(values: ValidationSchema) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        resolve();
      }, 1000);
    });
  }

  return (
    <Box as="form" onSubmit={hookform.handleSubmit(onSubmit)}>
      <Heading as="h2" size="lg" color="heading" mb={6}>
        Contact Information
      </Heading>
      <Box bgColor="white" rounded="xl" p={6} shadow="sm">
        <Grid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
          columnGap={4}
          rowGap={6}
        >
          <Flex gap={{ base: 3, sm: 4 }}>
            <Box flex="0.5">
              <FormSelect
                isRequired
                hookform={hookform}
                label="Title"
                name="title"
              >
                <option value="MR">MR</option>
                <option value="MRS">MRS</option>
                <option value="MS">MS</option>
              </FormSelect>
            </Box>
            <Box flex="1">
              <FormInput
                isRequired
                hookform={hookform}
                label="First Name"
                name="firstName"
                placeholder="First Name"
              />
            </Box>
          </Flex>
          <FormInput
            isRequired
            hookform={hookform}
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
          />
          <FormInput
            type="email"
            isRequired
            hookform={hookform}
            label="Email Address"
            name="email"
            placeholder="Email Address"
          />
          <FormPhone
            isRequired
            hookform={hookform}
            label="Phone Number"
            name="phone"
          />
          <FormSelect
            isRequired
            hookform={hookform}
            label="Country/Region"
            name="country"
            placeholder="Select a country"
          >
            <option value="Nigeria">Nigeria</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="United States of America">
              United States of America
            </option>
          </FormSelect>
          <FormInput
            isRequired
            hookform={hookform}
            label="Town or City"
            name="city"
            placeholder="Town or City"
          />
          <FormInput
            isRequired
            hookform={hookform}
            label="Street Address"
            name="address"
            placeholder="Street Address"
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactInformation;
