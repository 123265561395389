import { Container, SEO } from "@/components";
import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { FaPlaneDeparture } from "react-icons/fa";

interface Props {}

const Payment: React.FC<Props> = () => {
  const booking = useGlobalStore(state => state.booking);

  return (
    <>
      <SEO title="Payment" />
      <Container py={10}>
        <Flex align="flex-start" justify="space-between" gap={8}>
          <Box flex="1">
            <Box mb={4}>
              <Heading as="h2" size="lg" color="heading" mb={2}>
                Payment
              </Heading>
              <Text>Choose your preferred payment method</Text>
            </Box>
            <Box mb={6} bgColor="white" rounded="xl" overflow="hidden">
              <Box mb={6} pt={4} px={{ base: 4, md: 6 }}>
                <Flex mb={4} justify="space-between" gap={4}>
                  <Heading
                    as="h3"
                    size="md"
                    fontWeight="medium"
                    color="heading"
                  >
                    PayStack
                  </Heading>
                  <Image src="/images/paystack.svg" />
                </Flex>
                <Flex align="center" justify="space-between" gap={4}>
                  <Text>
                    You will be redirected to our secure payment checkout.
                  </Text>
                  <Flex align="center" gap={2}>
                    <Image src="/images/mastercard-small.svg" />
                    <Image src="/images/visa-small.svg" />
                    <Image src="/images/verve.svg" />
                  </Flex>
                </Flex>
              </Box>
              <Text color="heading" bgColor="grey" textAlign="center" p={4}>
                You will be charged{" "}
                <Text as="span" fontSize="xl" fontWeight="medium">
                  ₦131,000
                </Text>
              </Text>
            </Box>
            <Box
              mb={6}
              bgColor="white"
              rounded="xl"
              overflow="hidden"
              py={4}
              px={{ base: 4, md: 6 }}
            >
              <Flex mb={4} justify="space-between" gap={4}>
                <Heading as="h3" size="md" fontWeight="medium" color="heading">
                  Flutterwave
                </Heading>
                <Image src="/images/flutterwave.svg" />
              </Flex>
              <Checkbox mb={4}>Pay with Flutterwave</Checkbox>
              <Text color="heading">
                By selecting "Pay" you confirm reservation of selected services
                and agree with the conditions of carriage and the fare
                application rules of Green Africa.
              </Text>
            </Box>
            <Flex justify="end">
              <Button colorScheme="primary" minW="10rem" minH="3rem">
                Pay
              </Button>
            </Flex>
          </Box>
          <Flex
            direction="column"
            gap={2}
            flex="0.75"
            bgColor="white"
            rounded="xl"
            py={4}
            px={{ base: 4, md: 6 }}
          >
            <Text fontSize="lg">Tuesday 05 September 2023</Text>
            <Text
              fontSize="2xl"
              color="heading"
              fontWeight="semibold"
              display="inline-flex"
              alignItems="center"
              gap={2}
              mb={4}
            >
              <Icon color="secondary.main" as={FaPlaneDeparture} />
              {booking?.departureAirport.name} to {booking?.arrivalAirport.name}
            </Text>
            <Flex justify="space-between" gap={6} align="center">
              <Box>
                <Text fontSize="3xl" fontWeight="medium">
                  13:05
                </Text>
                <Text>ABV</Text>
              </Box>
              <Flex direction="column" align="center" flex="1 1 100%">
                <Button mb={1} textDecor="underline" size="sm" variant="link">
                  Direct
                </Button>
                <Box mb={4} bgColor="grey" h="2px" w="full" />
                <Text>1h 10m</Text>
              </Flex>
              <Box textAlign="right">
                <Text fontSize="3xl" fontWeight="medium">
                  14:15
                </Text>
                <Text>BNI</Text>
              </Box>
            </Flex>
            <Text
              bgColor="secondary.main"
              rounded="md"
              px={8}
              py={1}
              color="white"
              w="max-content"
            >
              Saver
            </Text>
            <Text textAlign="center">Flight Q9 314</Text>
            <Divider />
            <Flex fontWeight="bold" justify="space-between" gap={4}>
              <Text>Departure ABV - BNI</Text>
              <Text>₦68,500</Text>
            </Flex>
            <Flex fontWeight="bold" justify="space-between" gap={4}>
              <Text>Total price</Text>
              <Text>₦68,500</Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </>
  );
};

export default Payment;
