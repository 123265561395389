import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

interface Props<Field extends FieldValues> extends FormControlProps {
  hookform: UseFormReturn<Field>;
  label: string;
  name: Path<Field>;
}

const FormPhone = <Field extends FieldValues>(props: Props<Field>) => {
  const { hookform, label, name, ...rest } = props;

  return (
    <FormControl isInvalid={!!hookform.formState.errors[name]} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <PhoneInputWithCountry
        name={name}
        control={hookform.control}
        inputComponent={Input}
      />
      <FormErrorMessage>
        {hookform.formState.errors[name]?.message as string}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormPhone;
