import { Container, RouterLink } from "@/components";
import { formatPassengers } from "@/helpers";
import { formatDate } from "@/lib/utils";
import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import BookingNavigation from "./BookingNavigation";
import EditBookingModal from "./EditBookingModal";

const NAVIGATION = [
  { label: "Select Flight", path: "/booking/select" },
  { label: "Passengers", path: "/booking/passengers" },
  { label: "Services", path: "/booking/services" },
  { label: "Seat map", path: "/booking/seat-map" },
  { label: "Payment", path: "/booking/payment" },
  { label: "Confirmation", path: "/booking/confirmation" },
];

const BookingLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setStep, booking } = useGlobalStore();
  const { pathname } = useLocation();
  const currentPathIndex = NAVIGATION.findIndex(item => item.path === pathname);
  const nextPath = NAVIGATION[currentPathIndex + 1]?.path ?? "#";
  const handleNextStep = () => setStep(currentPathIndex + 1);

  return (
    <Box borderTop="1px solid" borderColor="gray.300">
      <Container py={4}>
        <Flex
          justify="space-between"
          align={{ sm: "stretch" }}
          direction={{ base: "column", sm: "row" }}
          gap={4}
        >
          <Flex gap={4} align="center">
            <Box>
              <Heading color="heading" size="lg" fontWeight="medium">
                {booking?.departureAirport.name} (
                {booking?.departureAirport.code}) to{" "}
                {booking?.arrivalAirport.name} ({booking?.arrivalAirport.code})
              </Heading>
              <Text>
                {formatDate(booking?.date)},{" "}
                {formatPassengers(booking?.passengers)}
              </Text>
            </Box>
            <Button colorScheme="secondary" variant="outline" onClick={onOpen}>
              Edit
            </Button>
          </Flex>
          <RouterLink to={nextPath} onClick={handleNextStep}>
            <Button
              px="4rem"
              colorScheme="primary"
              size="lg"
              minH="3rem"
              maxH="4rem"
              h="full"
              w="full"
            >
              Continue
            </Button>
          </RouterLink>
        </Flex>
      </Container>
      <Box pt={10} pb={20} bgColor="backgroundAlt">
        <BookingNavigation navigation={NAVIGATION} />
        <Box mb={10}>
          <Outlet />
        </Box>
      </Box>
      <EditBookingModal isOpen={isOpen} onClose={onClose} />;
    </Box>
  );
};

export default BookingLayout;
