import { Container } from "@/components";
import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FaMinus, FaPlaneDeparture, FaPlus, FaSuitcase } from "react-icons/fa";
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ExtraBaggageModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const booking = useGlobalStore(state => state.booking);

  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          px={{ base: 4, sm: 6 }}
          bgColor="secondary.main"
          textAlign="center"
          color="white"
        >
          Extra Baggage
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody py={8} px={{ base: 4, sm: 6 }} bgColor="backgroundAlt">
          <Container p={0}>
            <Text textAlign="center" mb={6}>
              Select one or more extra baggage options
            </Text>
            <Grid templateColumns={{ md: "1fr 1fr" }}>
              <Box>
                <Text
                  w="full"
                  as="h3"
                  fontSize="3xl"
                  color="heading"
                  fontWeight="semibold"
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                  mb={4}
                >
                  <Icon color="secondary.main" as={FaPlaneDeparture} />
                  {booking?.departureAirport.name} to{" "}
                  {booking?.arrivalAirport.name}
                </Text>
                <Text mb={4}>
                  Customer 1
                  <Text ml={2} as="span" fontWeight="medium">
                    Ignatius James
                  </Text>
                </Text>
                <Grid templateColumns={{ md: "1fr 1fr" }} gap={8}>
                  {Array.from(Array(3).keys()).map(index => (
                    <Flex
                      key={index}
                      bgColor="white"
                      boxShadow="sm"
                      direction="column"
                      align="center"
                      gap={4}
                      borderRadius="xl"
                      p={4}
                    >
                      <Icon
                        as={FaSuitcase}
                        color="secondary.main"
                        fontSize="4.25rem"
                      />
                      <Text fontWeight="medium">Extra bag up to 10kg</Text>
                      <Text>₦6,000</Text>
                      <Flex align="center" justify="center" gap={4}>
                        <IconButton
                          colorScheme="secondary"
                          size="sm"
                          aria-label="-1"
                          borderRadius="full"
                          bgColor="white"
                          variant="outline"
                          icon={<Icon as={FaMinus} />}
                          // isDisabled={}
                          // onClick={}
                        />
                        <Text fontSize="lg" fontWeight="medium" color="heading">
                          {0}
                        </Text>
                        <IconButton
                          colorScheme="secondary"
                          size="sm"
                          aria-label="+1"
                          borderRadius="full"
                          bgColor="white"
                          variant="outline"
                          icon={<Icon as={FaPlus} />}
                          // onClick={}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Container>
        </ModalBody>
        <ModalFooter px={{ base: 4, sm: 6 }}>
          <Container
            display="flex"
            gap={6}
            alignItems={{ sm: "center" }}
            flexDirection={{ base: "column", sm: "row" }}
            justifyContent="end"
            p={0}
          >
            <Flex
              align={{ base: "center", sm: "normal" }}
              flexDirection={{ base: "row", sm: "column" }}
              gap={2}
            >
              <Text>Subtotal</Text>
              <Text fontSize="2xl" color="heading">
                ₦6,000
              </Text>
            </Flex>
            <Button
              colorScheme="primary"
              onClick={onClose}
              size={{ base: "md", md: "lg" }}
            >
              Confirm
            </Button>
          </Container>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExtraBaggageModal;
