import { Container, SEO } from "@/components";
import {
  Box,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

interface Props {}

const PrivacyPolicy: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Privacy Policy" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Privacy Policy
        </Heading>

        <Box maxW="800px">
          <Heading as="h2" size="sm" color="heading" mb={2}>
            INTRODUCTION
          </Heading>
          <Text mb={4}>
            By accessing or visiting our website [*] (the &quot;Website&quot;),
            our mobile application (both Website and mobile app hereinafter
            referred to as &ldquo;Platforms&rdquo;) and more generally, use any
            of our services (the &quot;Services&quot;, which include the
            Platforms), you agree on behalf of yourself and any organization
            that you represent (together, &ldquo;you&rdquo;, &ldquo;your&rdquo;
            or &ldquo;User&rdquo;) that you have read and understood this
            Privacy Policy (the &ldquo;Policy&rdquo;) and that you consent to
            the collection, use, and sharing of information as discussed below.
          </Text>
          <OrderedList
            sx={{ "&>li::marker": { fontWeight: "bold", color: "heading" } }}
            mb={4}
          >
            <ListItem mb={4}>
              <Heading as="h1" size="sm" color="heading" mb={2}>
                PERSONAL INFORMATION WE COLLECT
              </Heading>
              <Text>
                Huriya Air collects and maintains personal information about you
                from many sources to understand and meet your needs. when you
                register on the Platforms, express an interest in obtaining
                information about us or our Services, when you participate in
                activities on the Platforms or otherwise when you contact us, we
                collect information across three cardinal points:
              </Text>
              <OrderedList
                listStyleType="lower-latin"
                marginInlineStart="2em"
                mb={2}
              >
                <ListItem>
                  Personally Identifiable Information (PII) voluntarily provided
                  by you;
                </ListItem>
                <ListItem>
                  Information provided by a third-party for the purpose of
                  verifying and/or augmenting a User’s PII; and
                </ListItem>
                <ListItem>
                  Information automatically provided as a result of User’s
                  engagement with our platform (terms of use are contained in
                  our Cookie Policy)
                </ListItem>
              </OrderedList>
              <Text>
                Personal Information we collect includes but is not limited to:
              </Text>
              <OrderedList
                listStyleType="lower-latin"
                marginInlineStart="2em"
                mb={2}
              >
                <ListItem>
                  Full name, physical residential address, nationality,
                  telephone number and email address;
                </ListItem>
                <ListItem>Date of birth, marital status and gender; </ListItem>
                <ListItem>
                  Bank account number, credit or debit card details and billing
                  address;
                </ListItem>
                <ListItem>
                  Photographic Identification, which includes but is not limited
                  to international passport details and driver’s license;
                </ListItem>
                <ListItem>
                  Corporate address, tax identification number and employer
                  contact information; and
                </ListItem>
                <ListItem>
                  Other technical information like user IP address, browser type
                  and internet service provider
                </ListItem>
              </OrderedList>
              <Text>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information. Also, we collect information through
                cookies and similar technologies like many other businesses.
              </Text>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                PURPOSE OF COLLECTION
              </Heading>
              <Text>
                Huriya Air collects Users&rsquo; personal information to provide
                an efficient and secure User experience and may retain such
                personal data for the period necessary to fulfil the purposes
                outlined in this Policy unless a longer retention period is
                required or permitted by law. In accordance with relevant laws,
                We may collect or process your information on the following
                legal basis:
              </Text>
              <UnorderedList marginInlineStart="2em" mb={2}>
                <ListItem>
                  Consent: We may process your information if you have given us
                  specific consent to use your personal information for a
                  specific purpose.
                </ListItem>
                <ListItem>
                  Legitimate Interests: We may process your information when it
                  is reasonably necessary to achieve our legitimate business
                  interests.
                </ListItem>
                <ListItem>
                  Performance of a Contract: Where we have entered into a
                  contract with you, we may process your personal information to
                  fulfill the terms of our contract.
                </ListItem>
                <ListItem>
                  Legal Obligations: We may disclose your information where we
                  are legally required to do so in order to comply with
                  applicable law, governmental requests, a judicial proceeding,
                  court order, or legal process, such as in response to a court
                  order or a subpoena (including in response to public
                  authorities to meet national security or law enforcement
                  requirements).
                </ListItem>
                <ListItem>
                  Vital Interests: We may disclose your information where we
                  believe it is necessary to investigate, prevent, or take
                  action regarding potential violations of our policies,
                  suspected fraud, situations involving potential threats to the
                  safety of any person and illegal activities, or as evidence in
                  litigation in which we are involved.
                </ListItem>
              </UnorderedList>
              <Text>
                More specifically, Information gathered on the Platforms form
                each User may be used to:
              </Text>
              <OrderedList listStyleType="lower-latin" marginInlineStart="2em">
                <ListItem>
                  fulfill legal and contractual obligations to Users;
                </ListItem>
                <ListItem>
                  develop, operate, support, maintain enhance and provide its
                  services;
                </ListItem>
                <ListItem>process payment transactions;</ListItem>
                <ListItem>
                  provide receipts and reports on User&rsquo;s account;
                </ListItem>
                <ListItem>
                  resolve disputes arising from using our Platforms;
                </ListItem>
                <ListItem>
                  customize measure, and improve the Services offered on our
                  Platforms;
                </ListItem>
                <ListItem>
                  protect the interests and rights of our Platform;
                </ListItem>
                <ListItem>
                  protect the health and safety of our employees and customers;
                </ListItem>
                <ListItem>protect our property</ListItem>
                <ListItem>enforce our agreements on the Platforms;</ListItem>
                <ListItem>
                  detect and prevent fraud and other potentially illegal
                  activities;
                </ListItem>
                <ListItem>
                  for administrative, operational and reporting purposes;
                </ListItem>
                <ListItem>
                  promote marketing communication (taking into consideration the
                  option to opt out);
                </ListItem>
                <ListItem>
                  manage and protect the Platforms&rsquo; information technology
                  and physical infrastructure; and
                </ListItem>
                <ListItem>
                  measure the performance of the Platforms and improve content,
                  technology and layout
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                DATA SUBJECT CONSENT
              </Heading>
              <Box marginInlineStart="1em">
                <Text>
                  <Text as="span" fontWeight="bold">
                    3.1
                  </Text>{" "}
                  Generally, we rely on your clear, unequivocal and
                  comprehensible consent as the legal basis for processing your
                  data. This covers cases where you require us to process your
                  data for the purpose of performing a contract with you, or
                  accessing our services, raising a complaint for clearance of
                  faults; whistleblowing; accessing any of our customer care
                  services; or for use in our marketing and advertising
                  services. In any of the foregoing instances, we will seek your
                  consent before we share your personal data with any third
                  party for such purposes.
                </Text>
                <Text>
                  <Text as="span" fontWeight="bold">
                    3.2
                  </Text>{" "}
                  With regards to what constitutes data subject&rsquo;s consent,
                  in accordance with the applicable laws and regulations, Huriya
                  Air shall ensure that consent is freely given and is obtained
                  following full disclosure of its intended us and without
                  fraud, coercion or undue influence. Since the performance of a
                  contract with you and provision of our services to you are
                  conditional on your consent, we shall deem that you have
                  granted us the free and express consent to collect and process
                  your personal data when you fill any of our forms, access or
                  create an account on our Platforms, correspond with us by
                  post, phone, email as well as other related medium/platforms.
                  PLEASE NOTE THAT YOU HAVE THE RIGHT TO WITHDRAW CONSENT AT ANY
                  TIME TO THE PROCESSING OF YOUR PERSONAL DATA BY CONTACTING OUR
                  DATA PROTECTION OFFICER (at [*]). Please note however that the
                  withdrawal of consent will not affect the lawfulness of the
                  processing before its withdrawal, nor will it affect the
                  processing of your personal information conducted in reliance
                  on lawful processing grounds other than consent.
                </Text>
                <Text>
                  <Text as="span" fontWeight="bold">
                    3.3
                  </Text>{" "}
                  Where we need to collect personal data by law, or under the
                  terms of a contract we have with you, or to provide our
                  products and our services and you fail to provide that data
                  when requested, we may not be able to perform the contract we
                  have or are trying to enter into with you (including to
                  provide you with our products and services). In such case, we
                  may have to cancel a product or service you have with us but
                  we will notify you if this is the case at the time.
                </Text>
              </Box>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                DATA SUBJECT RIGHT
              </Heading>
              <Text>
                Subject to applicable law, regulations and/or guidelines, you
                may have the following rights:
              </Text>
              <Text as="h4" fontWeight="semibold">
                Consent
              </Text>
              <Text mb={2}>
                To be informed of and to provide consent prior to the use of
                your personal data for purposes other than that for which it was
                initially collected
              </Text>
              <Text as="h4" fontWeight="semibold">
                Access
              </Text>
              <Text mb={2}>
                To request a copy of the personal data processed in relation to
                you. Please note that we may, by law, charge a fee for this.
              </Text>
              <Text as="h4" fontWeight="semibold">
                Correction
              </Text>
              <Text mb={2}>
                To request that we correct your personal data found by you to be
                incorrect or to have changed.
              </Text>
              <Text as="h4" fontWeight="semibold">
                Erasure
              </Text>
              <Text mb={2}>
                To ask us to delete your personal data, for example if we no
                longer have a valid reason to process it.
              </Text>
              <Text as="h4" fontWeight="semibold">
                Object
              </Text>
              <Text mb={2}>
                To object to how we process your personal data. This does not
                mean you can decide or choose how we process your personal data
                other than in relation to marketing. If you have any concerns
                about how we process your personal data, please contact us at
                [*]. Please note that we may not be able to offer you services
                if you do not want us to process the personal data which we
                consider necessary for the provision of that services.
              </Text>
              <Text as="h4" fontWeight="semibold">
                Portability of data
              </Text>
              <Text>
                To request the movement of data from us to a third Party.
              </Text>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                SHARING INFORMATION WITH THIRD PARTIES
              </Heading>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  5.1{" "}
                </Text>
                We may share your information with third-party service providers
                for the purpose of validating your credentials; securing data
                storage, marketing, advertising, customer service, and other
                applicable services and we require that these third-party
                providers use Personal Data only in connection with the services
                they perform for Huriya Air. in connection with the services
                they perform for Huriya Air.
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  5.2{" "}
                </Text>
                Third parties to whom Huriya Air outsources all or part of
                personal data processing activities must also comply with this
                Policy. We will validate the privacy policy of any new
                third-party with whom we engage, that requires us providing
                personal data of Users, Merchants, employees and others.
                Existing third-party service providers will also be subject to
                validation.
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  5.3{" "}
                </Text>
                We may share Users&rsquo; non-personally identifiable
                information with third parties that help us better understand
                how Users use our Service, or help us detect and prevent fraud
                and other unauthorized or suspicious activity. These
                third-parties may use cookies and other technologies to collect
                non-personally identifiable information about Users and combine
                it with similar information collected from others. They may use
                this information to help us better understand our Users, and to
                help their other customers better understand the Users.
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  5.4{" "}
                </Text>
                Huriya Air may share User information in the event of a merger,
                acquisition, debt financing, sale of all or a portion of our
                assets, or similar transaction, or in the event of insolvency,
                bankruptcy or receivership in which User information is
                transferred to one or more third parties as one of our business
                assets. Should such an event occur, Huriya will endeavor to
                ensure that the acquirer, successor, or assignee (as the case
                may be) follows this Privacy Policy with respect to User
                information. If User information could be used contrary to this
                Privacy Policy, Users will receive prior notice as well as the
                opportunity to opt out.
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  5.5{" "}
                </Text>
                Huriya Air may share User information with law enforcement,
                government officials, or other third parties in the event of a
                subpoena, court order or similar legal procedure, or when Huriya
                Air believes in good faith that the disclosure of User
                information is necessary or advisable to report suspected
                illegal activity, or to protect Huriya&rsquo;s property or legal
                rights or the property or rights of others, or otherwise to help
                protect the safety or security of the Services.
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  5.6{" "}
                </Text>
                Except as expressly disclosed in this Privacy Policy, Huriya Air
                will not sell or disclose Users&rsquo; information to third
                parties. Huriya Air will not sell, rent, share, or trade PII to
                third parties (other than the Platforms through which Huriya Air
                collected such information) for their promotional purposes.
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">
                  5.7{" "}
                </Text>
                We may share personally identifiable information with third
                parties for the purposes set out in Section 2 of this Policy
                above, and in compliance with the applicable laws and
                regulations. We may also share non-identifiable information with
                third parties that help us prevent fraud and analyze website
                activity.
              </Text>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                RETENTION POLICY
              </Heading>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  6.1{" "}
                </Text>
                We will only retain your personal data for as long as reasonably
                necessary to fulfill the purposes for which we collected it,
                including the satisfaction of any legal, regulatory, tax,
                accounting or reporting requirements. We may retain your
                personal data for a longer period if required by applicable law,
                in the event of a complaint or if we reasonably believe there is
                a prospect of litigation in respect to our relationship with
                you.
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">
                  6.2{" "}
                </Text>
                To determine the appropriate retention period for personal data,
                we consider the amount, nature and sensitivity of the personal
                data, the potential risk of harm from unauthorised use or
                disclosure of your personal data, the purposes for which we
                process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal,
                regulatory, tax, accounting or other requirements
              </Text>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                GOVERNING PRINCIPLES
              </Heading>
              <Text>
                Huriya Air will comply with the principles outlined below for
                the purpose of collecting, storing and using a User&rsquo;s
                personal information.
              </Text>
              <OrderedList listStyleType="lower-latin" marginInlineStart="2em">
                <ListItem>
                  Data shall be collected and processed with a specific,
                  legitimate and lawful purpose which shall be consented to by
                  the User before collection and processing;
                </ListItem>
                <ListItem>
                  Data may be further processed for archiving, scientific
                  research, historical research and statistical purposes for
                  public interest without the obtaining the consent of the User;
                </ListItem>
                <ListItem>
                  Data collection and processing shall be adequate, accurate and
                  with consideration for dignity of human person;
                </ListItem>
                <ListItem>
                  Data shall only be stored for the period which is reasonably
                  needed and as required by any written law; and
                </ListItem>
                <ListItem>
                  Data shall be secured against all foreseeable hazards and
                  breaches such as theft, cyberattack, viral attack,
                  dissemination, manipulations of any kind, damage by rain, fire
                  or exposure to other natural elements.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                REPORTING PERSONAL DATA BREACH
              </Heading>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  8.1{" "}
                </Text>
                Huriya Air is required to comply with the Nigeria Data
                Protection Regulation 2019 (&ldquo;NDPR&rdquo;) and other
                relevant regulations regarding reporting requirements in
                relation to data breaches and report any personal data breach.
                Huriya Air has a duty to report to NITDA within 72 hours of
                becoming aware of a breach and to notify the User within 7
                working days except otherwise directed by NITDA. .
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  8.2{" "}
                </Text>
                Huriya Air has placed procedures around its Platforms to deal
                with any suspected personal data breach and will notify a User
                or NITDA (where legally required to do so). Any suspected breach
                of personal data of a User will be remedied within one (1) month
                from the date of the report of the breach.
              </Text>
              <Text mb={2}>
                <Text as="span" fontWeight="bold">
                  8.3{" "}
                </Text>
                All evidence relating to a personal data breach should be
                preserved to enable Huriya Air to maintain a record of such
                breaches, as required by the data protection laws and to
                preclude future recurrence.
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">
                  8.4{" "}
                </Text>
                Huriya Air will not be responsible for any personal data breach
                which occurs as a result of:
              </Text>
              <OrderedList listStyleType="lower-latin" marginInlineStart="3em">
                <ListItem>an event which is beyond its control;</ListItem>
                <ListItem>an act or threats of terrorism;</ListItem>
                <ListItem>
                  an act of God (such as, but not limited to pandemics, fires,
                  explosions, earthquakes, drought, tidal waves and floods);
                </ListItem>
                <ListItem>
                  war, hostilities (whether war be declared or not), invasion,
                  act of foreign enemies, mobilisation, requisition, or embargo;
                </ListItem>
                <ListItem>
                  rebellion, revolution, insurrection, or military or usurped
                  power, or civil war which compromises the data protection
                  measures on Huriya&rsquo;s Platform;
                </ListItem>
                <ListItem>
                  the transfer of a User&rsquo;s personal data to a third party
                  on his/her instructions; and
                </ListItem>
                <ListItem>
                  the use of a User&rsquo;s personal data by a third party
                  designated by a User.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                POLICY UPDATES
              </Heading>
              <Text>
                This Policy may be updated from time to time and we shall notify
                you of any changes thereon via a notice on our website. It is
                also important that the personal data we hold about you is
                accurate a notice on our website. It is also important that the
                personal data we hold about you is accurate and current. In view
                of this, we encourage you to always update your personal data
                and keep us informed if there are changes to your personal data
                during your relationship with us.
              </Text>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                DATA PROTECTION OFFICER
              </Heading>
              <Text>
                <Text as="span" fontWeight="bold">
                  10.1{" "}
                </Text>
                To ensure that any concerns you may have regarding the
                protection of your personal data is addressed sufficiently and
                timeously, we have appointed a Data Protection Officer (DPO) who
                is responsible for overseeing questions in relation to this
                Policy.
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">
                  10.2{" "}
                </Text>
                Without prejudice to any other provisions of this Policy, if you
                have any questions about this Policy or our privacy practices,
                including any requests to exercise your legal rights which have
                been specified in this Policy, please contact the DPO at [*]:
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">
                  10.3{" "}
                </Text>
                Without prejudice to your right to make a complaint at any time
                to the NITDA, the supervisory authority for data protection
                issues (
                <Link
                  color="primary.600"
                  fontWeight="medium"
                  isExternal
                  href="//www.nitda.gov.ng"
                >
                  www.nitda.gov.ng
                </Link>
                ) for any alleged breach of your data privacy rights, we would
                appreciate that you contact us in the first instance through the
                DPO if you have any concerns regarding the protection of your
                data or this Policy.
              </Text>
            </ListItem>
            <ListItem mb={4}>
              <Heading as="h3" size="sm" color="heading" mb={2}>
                GOVERNING LAW
              </Heading>
              <Text>
                This Policy is made pursuant to the provisions of the NDPR and
                any other relevant Nigerian laws,
              </Text>
              <Text>
                regulations, or international conventions applicable to Nigeria
              </Text>
            </ListItem>
          </OrderedList>
          <Box>
            <Heading
              as="h3"
              size="sm"
              color="heading"
              mb={2}
              textDecor="underline"
            >
              CONSENT CLAUSE
            </Heading>
            <Text>
              In accordance with the provisions of the Nigerian Data Protection
              Regulations that consent must be a freely given, specific,
              informed and unambiguous indication of a Data Subject&apos;s
              agreement to the processing of his/her Personal Data, I hereby
              confirm that I have read the foregoing policy and with a clear
              understanding of its implications as regards my privacy rights
              consent to the processing of my personal data by Huriya Air
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
