import { Container as ChakraContainer, ContainerProps } from "@chakra-ui/react";

const Container: React.FC<ContainerProps> = ({ maxW, children, ...props }) => {
  return (
    <ChakraContainer
      maxW={
        maxW ?? {
          lg: "container.lg",
          xl: "container.xl",
        }
      }
      {...props}
    >
      {children}
    </ChakraContainer>
  );
};

export default Container;
