import { FAQ, Place } from "@/types";

export const DESTINATIONS = [
  {
    city: "Lagos to Maiduguri",
    image: "https://shorturl.at/bloBT",
    price: 50000,
  },
  { city: "Yola to Abuja", image: "https://shorturl.at/aBJV3", price: 35000 },
  {
    city: "Benin city to Katsina ",
    image: "https://shorturl.at/jwCO7",
    price: 62000,
  },
  { city: "Ibadan to Lagos", image: "https://shorturl.at/zDJQ6", price: 44500 },
  {
    city: "Kano to Port Harcourt",
    image: "https://shorturl.at/hiIQU",
    price: 23600,
  },
  { city: "Sokoto to Akure", image: "https://shorturl.at/yCLZ9", price: 31400 },
];

export const PLACES: Place[] = [
  {
    name: "Abuja",
    code: "ABV",
    country: "Nigeria",
    image: "https://shorturl.at/aBJV3",
    description: `A city geographically and strategically located right in the middle of Nigeria. Surrounding it is Niger to the west and northwest, Kaduna to the northeast, Nassarawa to the east and south, and Kogi to the southwest.

As the Federal Capital Territory of Nigeria, Abuja holds a diverse range of cultures from all parts of the country. The Aso Rock Villa is one enormous monolith that dominates the city with many other landmarks.

As you approach Abuja, the city's beauty cannot go unnoticed even from the skies. The careful planning is evident and more appealing in aerial view. So, when next you fly to Abuja, look out your window to feed your eyes.

For Abuja residents, hiking trails and public parks are features they are super proud to have and share with visitors. Be sure to pack your hiking boots on your next visit to Abuja. When you need some chill and thrill, make sure to get a taste of some of the best Kilishi you can
have in the North.`,
  },
  {
    name: "Lagos",
    code: "LOS",
    country: "Nigeria",
    image: "https://shorturl.at/zDJQ6",
    description: `Lagos, Eko, Lasgidi or Lag...the city with so many names is the commercial capital and economic nerve of Nigeria. When you travel by air, you can gaze upon the waters, creeks, and islands that gave the city its name. Towards the outskirt, you might lock eyes with the three white-capped chiefs’ statue popularly known as Aro Metta.

To get around the city, the yellow of Lagos shines. The widely popular yellow bus called Danfo will take you closer to the top sights and attractions in the city. If you prefer a private ride, you also have ride-hailing services available.

No matter where you go, you are sure to find unique attributes that define Lagos. From iconic landmarks to historical events and social activities, the spirit of Lagos is always alive. Whether your visit is business-driven or for leisure, Lagos has all you need and a million things you never imagined.`,
  },
  {
    name: "Port Harcourt",
    code: "PHC",
    country: "Nigeria",
    image: "https://shorturl.at/hiIQU",
    description: `A tropical wet climate south-south Nigerian city that is well known for its contribution to the A tropical wet climate south-south Nigerian city that is well known for its contribution to the Nigerian’s global market strength and overall GDP through its crude oil rich lands. The city houses many multinational firms as part of its industrial complex. With two main oil refineries, Port-Harcourt produces over 200,000 barrels of crude oil per day and that cements the city’s claim to be one of the wealthiest regions in Nigeria.

Apart from the industrial nature of Port-Harcourt, nightlife, music and entertainment are a big part of the city’s lifestyle.

The people of this city love to celebrate life and they have the wealth to celebrate it quite well. Being a Delta, the city is surrounded by bodies of water which gives them access to lots of good food (especially seafood).

Port Harcourt International Airport is located at Omagwa, a suburb of the city. It has two terminals that serves both international and domestic flights. You get to fly out of Nigeria to international destinations or fly to other Nigerian cities through its domestic terminal. Grab a meal of Bole and fish as you explore the relaxed oil city of Port-Harcourt (or you can simply call it PH).`,
  },
  {
    name: "Enugu",
    code: "ENU",
    country: "Nigeria",
    image: "https://shorturl.at/ajrJS",
    description: `A southeastern Nigerian tropical rain forest city. Enugu’s climate is humid, and this humidity is at its highest between March and November.

The name of the city comes from two Igbo words, Énú Ụ́gwụ́ meaning "hilltop" and there is a lot of history behind it.

Enugu, as its now known, developed through the discovery of coal during the 17th century in Enugwu Ngwuo village. The commercial significance of coal drew people from every clan and village to come make a living.

In present-day Enugu, you will find cityscapes like the African Continental Bank (ACB) tower, the Hotel Presidential and many others.

The people of Enugu take pride in their culture, and you have a chance to experience it firsthand. Annually, the New Yam festival takes place between August and October while Mmanwu festival takes place in November.

When a city takes pride in festivities, you can bet there are other forms of entertainment in store. During the 1950s, Enugu was a center for Highlife music that further spread in popularity to as far as Southern Cameroon. Today, the genre is still much alive through the city’s indigenous musical talents.

There are several traditional foods that are exclusively to Enugu. These local dishes include Okpa, Abacha, Agbugbu, Achicha, and Akidi.`,
  },

  {
    name: "Akure",
    code: "AKR",
    country: "Nigeria",
    image: "https://shorturl.at/yCLZ9",
    description: `Akure is a southwestern Nigerian tropic rainforest city and the capital of Ondo State. It is a significant contributor to the agricultural economy of Nigeria. Over the years, Akure has produced and continues to farm cocoa, yams, cassava, corn, and many other farm products. Asides from its agricultural exploits, it is a beaming light of youthfulness. It is especially true with the presence of the Federal University of Technology.

The university brings in a vibrant group of young and entrepreneurial minds into the city. Their drive excels through tech and other emerging career paths that help build new solutions for new communities.

Here are some interesting places and things to do in the Akure - A visit to the Deji of Akure Palace will bring you closer to the cultural heritage of the people of Akure. The Akure City Hall and the Cultural Centre (Adegbemile) are popular places for film shows during holidays. Also, the Cultural Centre holds a gallery where you can see great and beautiful artwork. When it comes to food, Akure has a few native dishes such as Ekuru Ogbede, Egbidi and Ọjọjọ, which is made with grained water yam.

When you want to have a bird’s eye view, the Idanre Hills will give you a fair challenge on your way to the hilltop.

Ikogosi Warm Springs is another natural wonder with a proximity to Ondo State. It is one of the natural wonders, famous for its confluence of warm and cold springs. Akure is one of Nigeria’s best-kept tourism secrets.`,
  },
  {
    name: "Owerri",
    code: "QOW",
    country: "Nigeria",
    image: "https://shorturl.at/jwCX4",
    description: `Popularly referred to as the Heartland, Owerri is known as the entertainment capital of Nigeria with hotels, casinos, production studios and several high value relaxation centers. The city runs annual beauty pageants, known as "Miss Heartland" and "Miss Adanma".

The tropical wet climate of Owerri allows production of yams, cassava, taro, corn, rubber and other palm products. The city also sits on huge crude oil and natural gas reserves like Port-Harcourt.

To be in Owerri is to savor the famous Ofe Owerri (meaning Soup from Owerri). It is sometimes referred to as the king of soup in some Igbo communities.

Flying into the city will see you land at the Sam Mbakwe Airport, which also serves Onitsha, Nnewi in Anambra State, Aba, Umuahia and Arochukwu in Abia State.

Whatever your plans are in or out of Owerri, do not leave the city without having a taste of Ofe Owerri and Akpu.`,
  },
];

export const FAQs: FAQ[] = [
  {
    heading: "Booking a flight",
    body: [
      {
        question: "How can I book with Huriya Air?",
        answer: `Book your next flight or Getaway with Huriya Air - online at…….
        <br/>
        You may also contact our Care center on ………….would be happy to help.
        <br/>
        Please note that a fee of 2,000NGN will be applied.
        <br/>
        Don't see a flight you need? The flights may have been sold out or cancelled for operational reasons. We keep releasing our upcoming flight schedules periodically. All current times and routes are up to date and looking great!`,
      },
      {
        question: "How many seats can I book at one time?",
        answer: `You are able to book up to 9 seats per booking.
        <br/>
        To make a booking of 10 or more seats, please contact our Care center on…`,
      },
      {
        question:
          "Can I take a round trip flight that leaves out of a different city for the return?",
        answer: `In this case, you will need to book two one-way flights.`,
      },
      {
        question: "When do I have to pay for my flight?",
        answer: `Payment is required at the original time of booking. When you click the 'pay' button at the bottom of the payments page, you're good to go!`,
      },
      {
        question: "How can I pay for my flight?",
        answer: `We accept all major cards including Visa, Mastercard, Mastercard Debit, Verve and Visa Debit.`,
      },
      {
        question: "Are payments made through the Huriya Air website secure?",
        answer: `Absolutely! You can be rest assured your information is fully encrypted to keep the computer ninjas at bay. Safety and your privacy are our number one priority!`,
      },
      {
        question: "How do I get my boarding pass?",
        answer: `Your boarding pass will be available via email when you check-in.
        <br/>
        As an economy class Customer, you can check-in online within 24 hours and up to 3 hours before departure. Airport check-in attracts a N2,000 fee for economic and business class customers.
        <br/>
        <br/>
        As a business or first class Customer, you can check-in online within 48 hours and up to 3 hours before departure. Airport check-in is available at no extra fee for first class Customers.
        <br/>
        <br/>
        Once you have checked-in, you may download your boarding pass. Be sure to have your boarding pass accessible when at the airport.`,
      },
      {
        question: "Can I book a flight at the airport in person?",
        answer: `You can book a flight up to one hour before your flight at the airport for a fee of 2,000NGN. 
        <br/>
        You can also book online………… up to one hour prior to flight departure or call the Care Center on…….`,
      },
      {
        question: "At what age can children travel alone?",
        answer: `A child must be at least 12 years old to travel alone as an unaccompanied minor.`,
      },
      {
        question: "When is the best time to book a flight?",
        answer: `In general, the earlier you book the better. While we can't guarantee prices, they do tend to go up as departure dates get closer (especially over holidays and weekends).`,
      },
      {
        question:
          "Want to book a flight but can't find the dates or routes I want?",
        answer: `All routes and dates listed on the website are active. If you cannot find a date or destination, the route may be seasonal or discontinued. We suggest signing up to the Flyer Community on………… to ensure you are kept in the loop on new routes or destinations.`,
      },
      {
        question:
          "My flight price has decreased since I booked, can I get a refund reimbursement?",
        answer: `Huriya Air fares are based on availability. Occasionally fares will drop due to marketing directives or temporary promotions. As noted in Huriya’s terms and conditions, we cannot retroactively apply the current price to the booking you've already made.`,
      },
      {
        question: "Why am I seeing changing prices while booking?",
        answer: `Prices are driven by demand for each flight at different flight times and as such, you may see different prices.`,
      },
      {
        question: "How can I book a large group?",
        answer: `If you are looking to book a group of 15 or more people for a destination wedding, sports event, family reunion, or any other large group event, send us a email at……..`,
      },
      {
        question:
          "I have a middle name on my ID but not on my booking, do I need to add it?",
        answer: `Middle names are not a requirement for travel, and therefore it does not need to be added to your reservation. However, a traveler's first and last name must match the identification they are using for travel.`,
      },
      {
        question: "Do you sell gift cards or vouchers?",
        answer: `Huriya Air does not currently sell gift cards or vouchers.
        <br/>
        Alternatively, you can purchase a trip for someone as a Present! Be sure to check out our terms and conditions first.`,
      },
      {
        question: "Do you have a frequent flyer rewards program?",
        answer: `We're flattered you love to fly with us! Huriya Air believes in providing the best fares and service to maintain loyalty. If we decide to have a frequent flyer rewards program, we will let you know!`,
      },
      {
        question:
          "Why does my receipt show more bags or seats than I purchased? Was I charged twice?",
        answer: `Baggage and seats are charged each direction of your trip.
        <br/>
        They can be added to each leg of your flight independently. If you chose one bag (or seat) for both the outbound and return flights, this will show as two bags (or seats) on your receipt.`,
      },
      {
        question:
          "Why does the pricing calendar show a different price than the flight page?",
        answer: `There could be two possible reasons for this:
        <ol style="margin-left: 2rem;">
          <li>
            You are booking for a group or more than one person.
            <br/>
            We may have one fare left at the calendar price, but if there are 2 or more people flying, then we will show them the next lowest fare bucket.
          </li>
          <li>
          Flight schedules are refreshed faster than the calendar.
          </li>
        </ol>
        <br/>
        Please refer to the flight page for the most up-to-date availability and prices.`,
      },
      {
        question:
          "I'm having issues booking my 11 year old who turns 12 during the trip.",
        answer: `
        Help!
        <br/>
        The ages of passengers can only be entered once per booking.
        <br/>
        This may cause you to see an error when trying to book a passenger who has a birthday on your trip. This happens because Huriya Air classifies passengers aged 0-2 as an infant, 2-11 as a child, and 12+ as an adult.
        <br/>
        In this situation book two one-way trips; one as a child and one as an adult.`,
      },
    ],
  },
  {
    heading: "Baggage",
    body: [
      {
        question: "How many bags can I fly with?",
        answer: `Your baggage allowance is dependent on the fare product purchased.
        <ul style="margin-left: 2rem;">
          <li>As an economic class Customer, you are entitled to 7kg carry-on baggage only.</li>
          <li>As a business class Customer, you are entitled to 7kg carryon baggage and 1 item, not weighing more than 15kg checked baggage.</li>
          <li>As a first class Customer, you are entitled to 7kg carry-on baggage and 1 item, not weighing more than 23kg checked baggage.</li>
          <li>For Infants, an additional 5kg checked baggage is allowed.</li>
        </ul>
        You can however purchase additional baggage on a per kg basis at the airport.
        <br/>
        Excess bags (only 2 checked bags allowed)`,
      },
      {
        question: "What is the carry-on baggage dimension?",
        answer: `55cm x 40cm x 24cm is the dimension for carry-on baggage.
        <br/>
        Examples include small suitcases, small duffel bags or backpacks.`,
      },
      {
        question: "Do I have to pay for a carry-on bag?",
        answer: `No! As long as it is not more than 7kg.`,
      },
      {
        question: "What is a personal item?",
        answer: `It is one small bag that all travelers can bring on for free.
        <br/>
        Think small backpack, purse, or laptop case. Your personal item must fit under the seat in front of you. The maximum dimension is 55cm x 40cm x 24cm. As long as it is not more than 7kg.`,
      },
      {
        question: "Can I bring a personal item for free?",
        answer: `Yes. Please note, a personal item must fit under the seat in front of you and must not be more than 7kg.
        <br/>
        Maximum size: 55cm x 40cm x 24cm
        <br/>
        Examples include purses, laptop bags, briefcases, small backpacks, tote bags.`,
      },
      {
        question: "When can I add bags to my booking?",
        answer: `This is currently available at the original time of booking your flight on the Huriya Air website or while checking-in your flight online. You can also purchase additional baggage at the airport.`,
      },
    ],
  },
  {
    heading: "Flight changes and cancellations",
    body: [
      {
        question: "Is there a fee for changing my reservation?",
        answer: `Yes! There is a fee to change your reservation and it is dependent on the fare product purchased.`,
      },
      {
        question:
          "How do I make changes for only one person on the reservation?",
        answer: `Changes like this can be made by contacting our …………….Please keep in mind change fees may apply.`,
      },
      {
        question: "Can I change only the time of my flight?",
        answer: `Yes! You can change the time of your flight via the Manage Booking link on the Huriya Air website or by contacting our…….Please keep in mind change fees may apply.`,
      },
      {
        question: "Can I change only one part of my trip?",
        answer: `Yes! You can change a part of your trip via the Manage Booking link on the Huriya Air website or by contacting our ………... Please keep in mind change fees may apply.`,
      },
      {
        question: "What is your cancellation policy?",
        answer: `No voluntary cancellations are permitted for economic and business fare products.
        <br/>
        First class fare products allow for voluntary cancellations with refunds into your Huriya Air wallet.`,
      },
      {
        question: "What is your missed flights policy?",
        answer: `We would like you to be on time for your flight, but we know that life happens.
        <br/>
        Now you can reschedule missed flights with…...
        This is for customers who get to the check-in counter later than 30 minutes to their flight departure time.
        To reschedule missed flight with……, approach our airport ticketing counter or call…..
        <br/>
        Please note that Huriya Air and applicable fare differences apply.`,
      },
    ],
  },
];
