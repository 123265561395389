import { Container, RouterLink, SEO } from "@/components";
import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaSuitcase } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import ExtraBaggageModal from "./ExtraBaggageModal";

interface Props {}

const Services: React.FC<Props> = () => {
  const [value, setValue] = useState("1");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setStep, step } = useGlobalStore();
  const handleNextStep = () => setStep(step + 1);

  return (
    <>
      <SEO title="Services" />
      <Container py={10}>
        <Box mb={14}>
          <Heading as="h2" size="lg" color="heading" mb={2}>
            Services
          </Heading>
          <Text mb={6}>
            Make your trip even more comfortable. What would you like to add?
          </Text>
          <Button
            w={{ base: "full", sm: "auto" }}
            variant="unstyled"
            _hover={{ bgColor: "gray.50" }}
            display="flex"
            textAlign="left"
            h="auto"
            bgColor="white"
            borderRadius="xl"
            p={4}
            gap={{ base: 6, sm: 10 }}
            onClick={onOpen}
          >
            <Icon
              ml={{ md: 8 }}
              as={FaSuitcase}
              color="secondary.main"
              fontSize={{ base: "3rem", sm: "4.25rem" }}
            />
            <Box>
              <Text as="h3" fontSize="lg">
                Extra Baggage
              </Text>
              <Text fontSize="xs" fontWeight="light" mb={6}>
                Select one or more extra baggage options
              </Text>
              <Flex align="center" gap={4} justify="space-between">
                <Box>
                  <Text fontSize="xs">From</Text>
                  <Text fontSize="lg">₦6,000</Text>
                </Box>
                <Button
                  colorScheme="secondary"
                  as="span"
                  leftIcon={<Icon as={HiPlus} />}
                  size="xs"
                  onClick={onOpen}
                >
                  Add
                </Button>
              </Flex>
            </Box>
          </Button>
        </Box>
        <Box
          bgColor="white"
          borderRadius="sm"
          px={{ base: 4, sm: 12 }}
          py={{ base: 4, sm: 8 }}
          minH="500px"
        >
          <Text as="h3" fontSize="xl" fontWeight="medium" mb={10}>
            Special Assistance Request
          </Text>
          <RadioGroup colorScheme="secondary" onChange={setValue} value={value}>
            <Grid
              templateColumns={{ sm: "1fr 1fr" }}
              rowGap="3rem"
              fontWeight="semibold"
            >
              <Box>
                <Box mb={6}>
                  <Radio value="1">No Assistance Required</Radio>
                </Box>
              </Box>
              <Box>
                <Box mb={6}>
                  <Radio value="2">Wheelchair on the Ramp</Radio>
                </Box>
                <Text fontSize="xs" fontWeight="normal">
                  Customer Can Climb Stairs, Walk To And From Seat But Unable To
                  Walk Long Distances. Requires Assistance To And From The
                  Aircraft.
                </Text>
              </Box>
              <Box>
                <Box mb={6}>
                  <Radio value="3">Visually Impaired</Radio>
                </Box>
                <Text fontSize="xs" fontWeight="normal">
                  Customer requires full assistance to the aircraft and escort
                  inflight.
                </Text>
              </Box>
              <Box>
                <Box mb={6}>
                  <Radio value="4">Hearing Impaired</Radio>
                </Box>
                <Text fontSize="xs" fontWeight="normal">
                  Customer requires full assistance to the aircraft and escort
                  inflight.
                </Text>
              </Box>
            </Grid>
          </RadioGroup>
        </Box>
        <Flex justify="end" mt={10}>
          <RouterLink
            to="/booking/seat-map"
            onClick={handleNextStep}
            w={{ base: "full", sm: "auto" }}
          >
            <Button
              px="4rem"
              py="2rem"
              colorScheme="primary"
              size="lg"
              minH="3rem"
              maxH="4rem"
              w="full"
            >
              Skip step
            </Button>
          </RouterLink>
        </Flex>
      </Container>
      <ExtraBaggageModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Services;
