import { Booking } from "@/types";
import { create, StateCreator } from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface BookingSlice {
  step: number;
  setStep: (step: number) => void;
  booking: Booking | null;
  setBooking: (booking: Booking) => void;
}

export interface GlobalStore extends BookingSlice {}

const createBookingSlice: StateCreator<
  GlobalStore,
  [],
  [],
  BookingSlice
> = set => ({
  step: 0,
  setStep: (step: number) => set({ step }),
  booking: null,
  setBooking: (booking: Booking) => set({ booking }),
});

const useGlobalStore = create<
  GlobalStore,
  [["zustand/devtools", never], ["zustand/persist", GlobalStore]]
>(
  devtools(
    persist(
      (...a) => ({
        ...createBookingSlice(...a),
      }),
      { name: "global-store" }
    )
  )
);

export default useGlobalStore;
