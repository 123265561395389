import { Container, RouterLink } from "@/components";
import useGlobalStore from "@/store";
import { Button, Grid } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

interface Props {
  navigation: {
    label: string;
    path: string;
  }[];
}

const BookingNavigation: React.FC<Props> = ({ navigation }) => {
  const { pathname } = useLocation();
  const { setStep } = useGlobalStore();

  const isAfter = (index: number) => {
    const currentIndex = navigation.findIndex(({ path }) => path === pathname);
    return currentIndex < index;
  };

  return (
    <Container>
      <Grid
        as="ul"
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(6, 1fr)",
        }}
        boxShadow="sm"
        borderRadius="md"
        bgColor="white"
        mx="auto"
        maxW={{ md: "90%", lg: "85%" }}
        minH="3rem"
        alignItems="stretch"
        flexWrap="wrap"
      >
        {navigation.map(({ path, label }, index) => (
          <RouterLink
            to={!isAfter(index) ? path : "#"}
            key={index}
            onClick={() => {
              if (isAfter(index)) return;
              setStep(index);
            }}
          >
            <Button
              as="li"
              h="auto"
              w="full"
              minH="3rem"
              colorScheme={pathname === path ? "secondary" : "gray"}
              variant={pathname === path ? "solid" : "ghost"}
              isDisabled={isAfter(index)}
              position="relative"
              _after={{
                display: navigation.length - 1 === index ? "none" : "block",
                content: "''",
                h: "60%",
                w: "1px",
                bgColor: "secondary.500",
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {`${index + 1}. ${label}`}
            </Button>
          </RouterLink>
        ))}
      </Grid>
    </Container>
  );
};

export default BookingNavigation;
