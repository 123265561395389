import { Container, SEO } from "@/components";
import { Opening } from "@/types";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { BsSearch } from "react-icons/bs";
import OpeningCard from "./OpeningCard";

const OPENINGS: Opening[] = [
  {
    id: 1,
    title: "Software developer",
    location: "Abuja, Nigeria",
    datePosted: "18th Sep 2023",
    department: "Research and Development",
    level: "Entry Level",
    type: "Onsite",
  },
];

interface Props {}

const Careers: React.FC<Props> = () => {
  const boxRef = useRef<HTMLDivElement>(null);

  const scrollTo = () => {
    window.scrollTo({ behavior: "smooth", top: boxRef.current?.offsetTop });
  };

  return (
    <Box fontSize={{ base: "md", sm: "lg" }}>
      <SEO title="Careers" />
      <Box>
        <Container py="5rem">
          <Heading color="heading" as="h1" size="3xl" mb={10}>
            Find Your{" "}
            <Box as="span" color="primary.600">
              Dream Job
            </Box>{" "}
            in Huriya
          </Heading>
          <Button onClick={scrollTo} colorScheme="primary" size="lg">
            See Job Openings
          </Button>
        </Container>
        <Box bgColor="backgroundAlt" py="3rem">
          <Container>
            <Grid
              templateColumns={{ sm: "1fr 1fr", md: "0.5fr 1fr" }}
              gap={{ base: "2rem", sm: "2rem", md: "5rem" }}
              alignItems="center"
            >
              <Image w="full" src="/images/careers.svg" alt="careers" />
              <Box>
                <Heading color="heading" as="h2" size="xl" mb={8}>
                  More than a place to work
                </Heading>
                <Text>
                  Our work at Huriya Air is to drive change every day, while
                  rewarding our top-performers. We are a platform where ideas
                  thrive and we want every talent to find their sense of
                  purpose, belonging and ownership.
                </Text>
              </Box>
            </Grid>
          </Container>
        </Box>
        <Box ref={boxRef} id="openings" py="3rem">
          <Container>
            <Heading color="heading" as="h2" size="xl" mb={6}>
              Browse Openings
            </Heading>
            <Grid
              alignItems={{ md: "end" }}
              gap={{ base: 6, md: 20 }}
              templateColumns={{ md: "3fr 2fr" }}
              mb={6}
            >
              <Grid gap={4} templateColumns={{ sm: "1fr 1fr 1fr" }}>
                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Select>
                    <option value="">All</option>
                    <option value="Research and Development">
                      Research and Development
                    </option>
                    <option value="Maintenance and Engineering">
                      Maintenance and Engineering
                    </option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Location</FormLabel>
                  <Select>
                    <option value="">All</option>
                    <option value="Abuja, Nigeria">Abuja, Nigeria</option>
                    <option value="Lagos, Nigeria">Lagos, Nigeria</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <Select>
                    <option value="">All</option>
                    <option value="Onsite">Onsite</option>
                    <option value="Remote">Remote</option>
                  </Select>
                </FormControl>
              </Grid>
              <InputGroup size="md">
                <Input pr="2.75rem" placeholder="Search" />
                <InputRightElement width="2.75rem">
                  <IconButton
                    variant="ghost"
                    aria-label="search"
                    icon={<Icon as={BsSearch} />}
                    h="1.75rem"
                    size="sm"
                  />
                </InputRightElement>
              </InputGroup>
            </Grid>
            <Flex direction="column" gap={6}>
              {OPENINGS.map(opening => (
                <OpeningCard key={opening.id} opening={opening} />
              ))}
            </Flex>
          </Container>
        </Box>
        <Box
          bgColor="secondary.main"
          textAlign="center"
          py="3rem"
          color="white"
        >
          <Container>
            <Heading as="h2" size="xl" mb={6}>
              RAPID
            </Heading>
            <Text>
              We care about the candidates’ experience as much as you do, and we
              seek for new talents that will enrich our Culture and are a good
              fit. That’s why RAPID is our non-bias approach to recruiting
              future talents.
              <br />
              We can boast of a merit-based recruitment process, and you do not
              need to know anyone to get into the door.
              <br />
              <br />R - Resilience and Kind Spirit, A - Above board, P -
              Professional Excellence, I - Intellectual Curiosity and Humility D
              - Develop their Team and Others.
            </Text>
          </Container>
        </Box>
        <Box py="3rem" bgColor="backgroundAlt">
          <Container>
            <Heading color="heading" as="h2" size="xl" mb={6}>
              Our Future
            </Heading>
            <Text maxW="600px" mb={6}>
              A talent initiative aimed at developing the next generation of
              industry leaders, ushering a new era in Nigerian and African
              aviation.
              <br />
              <br />
              Young, inexperienced but talented and interested talents can now
              go through the Huriya Air Academy to gain skills and experiences
              across a spectrum of disciplines in the industry.
            </Text>
            <Button onClick={scrollTo} colorScheme="secondary" size="lg">
              See Job Openings
            </Button>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Careers;
