import { GrowRight, RouterLink } from "@/components";
import {
  Card,
  CardBody,
  CardProps,
  Heading,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { motion, useReducedMotion } from "framer-motion";
import { FaPlane } from "react-icons/fa";

interface Props extends CardProps {
  city: string;
  image: string;
  price: number;
}

const FlightsCard: React.FC<Props> = ({ city, image, price, ...props }) => {
  const shouldReduceMotion = useReducedMotion();
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: {
      opacity: shouldReduceMotion ? 1 : 0,
      x: shouldReduceMotion ? 0 : -100,
    },
  };

  return (
    <GrowRight h="20rem">
      <Card
        h="full"
        shadow="none"
        borderRadius="xl"
        position="relative"
        bgColor="rgba(0, 0, 0, 0.4)"
        overflow="hidden"
        _before={{
          content: "''",
          position: "absolute",
          inset: "0",
          zIndex: -1,
          bgImage: image,
          bgPos: "center",
          bgSize: "cover",
          bgRepeat: "no-repeat",
          bgColor: "heading",
        }}
        {...props}
      >
        <CardBody>
          <motion.div variants={item} transition={{ delay: 0.3 }}>
            <Heading
              as="h4"
              size="md"
              color="primary.main"
              mb={3}
              minH={{ base: "auto", md: "3rem" }}
            >
              {city}
            </Heading>
          </motion.div>

          <motion.div variants={item} transition={{ delay: 0.6 }}>
            <Text fontSize="xl" color="white" mb={4}>
              From ₦{price.toLocaleString()}
            </Text>
          </motion.div>

          <motion.div variants={item} transition={{ delay: 1 }}>
            <RouterLink to={`/destinations`}>
              <IconButton
                aria-label="view"
                borderRadius="full"
                bgColor="white"
                icon={<Icon as={FaPlane} />}
              />
            </RouterLink>
          </motion.div>
        </CardBody>
      </Card>
    </GrowRight>
  );
};

export default FlightsCard;
