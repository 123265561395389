import { Container, SEO } from "@/components";
import {
  Box,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface Props {}

const FareCategories: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Fare Categories" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Fare Categories
        </Heading>
        <Text mb={8}>
          Our fares are structured to provide you with choices as you plan to
          fly with us.
        </Text>
        <Flex flexFlow="row wrap" mx="-0.5rem">
          <Box flexBasis="100%" maxW="100%" flex="1 1 0%" px="0.5rem" pb="1rem">
            <Box mb="34px" overflowX="auto">
              <Table w="full" textAlign="left">
                <Thead>
                  <Tr
                    position={{ base: "absolute", md: "unset" }}
                    left={{ base: "-9999px", md: "unset" }}
                  >
                    <Th
                      fontWeight="semibold"
                      color="heading"
                      verticalAlign="bottom"
                      borderBottom="1px solid"
                      borderColor="heading"
                      scope="col"
                      rowSpan={1}
                      colSpan={1}
                      className="text-left"
                    ></Th>
                    <Th
                      fontWeight="semibold"
                      color="heading"
                      verticalAlign="bottom"
                      borderBottom="1px solid"
                      borderColor="heading"
                      scope="col"
                      rowSpan={1}
                      colSpan={1}
                      className="text-left"
                    >
                      Saver
                    </Th>
                    <Th
                      fontWeight="semibold"
                      color="heading"
                      verticalAlign="bottom"
                      borderBottom="1px solid"
                      borderColor="heading"
                      scope="col"
                      rowSpan={1}
                      colSpan={1}
                      className="text-left"
                    >
                      Classic
                    </Th>
                    <Th
                      fontWeight="semibold"
                      color="heading"
                      verticalAlign="bottom"
                      borderBottom="1px solid"
                      borderColor="heading"
                      scope="col"
                      rowSpan={1}
                      colSpan={1}
                      className="text-left"
                    >
                      Flex
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Th
                      _before={{
                        content: "''",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Flight date and time changes
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Flight date and time are changeable, subject to payment of
                      any applicable change fees. Fare difference may also apply
                      in the event of date/time change. No residual amount will
                      be given to the passenger in any event where the new fare
                      is lower than what was paid for the original booking. Date
                      and time changes are allowed up to 2 hours before a flight
                      scheduled departure time, otherwise the passenger has lost
                      the opportunity to make any changes to the booking and the
                      booking will be considered used if the passenger fails to
                      check-in and board the flight.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Flight date and time are changeable, subject to payment of
                      any applicable change fees. Fare difference may also apply
                      in the event of date/time change. No residual amount will
                      be given to the passenger in any event where the new fare
                      is lower than what was paid for the original booking. Date
                      and time changes are allowed up to 2 hours before a flight
                      scheduled departure time, otherwise the passenger has lost
                      the opportunity to make any changes to the booking and the
                      booking will be considered used if the passenger fails to
                      check-in and board the flight.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Flight date and time are changeable and does not attract
                      Change Fees for this fare product. Fare difference may
                      apply in the event of date/time change when a higher fare
                      class is selected. No residual amount will be given to the
                      passenger in any event where the new fare is lower than
                      what was paid for the original booking. Date and time
                      changes are allowed up to 2 hours before a flight
                      scheduled departure time, otherwise the passenger has lost
                      the opportunity to make any changes to the booking and the
                      booking will be considered used if the passenger fails to
                      check-in and board the flight.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Name Correction
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Correction for misspelt name can me made through Green
                      Africa Agents at the Contact Center for free. Only a
                      maximum of up to 3 characters can be changed in a name per
                      passenger. Note that an applicable fee applies when name
                      correction is done at any Airport desks.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Correction for misspelt name can be made through Green
                      Africa Agents at the Contact Center for free. Only a
                      maximum of up to 3 characters can be changed in a name per
                      passenger. Note that an applicable fee applies when name
                      correction is done at any Airport desks.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Correction for misspelt name can be made through Green
                      Africa Agents at the Contact Center for free. Only a
                      maximum of up to 3 characters can be changed in a name per
                      passenger. While you are advised to make your name
                      correction through our contact center, it can also be done
                      at the Airport desk at no cost for this fare product
                      class.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Infant Fare
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      A Fee of NGN10,000 is charged for the carriage of an
                      infant on the lap of the adult.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      A Fee of NGN10,000 is charged for the carriage of an
                      infant on the lap of the adult.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      A Fee of NGN10,000 is charged for the carriage of an
                      infant on the lap of the adult.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Child Fare
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      This is the same as fare for adults or for occupying a
                      seat.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      This is the same as fare for adults or for occupying a
                      seat.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      This is the same as fare for adults or for occupying a
                      seat.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Cabin Baggage
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Allowable 7KG Cabin Baggage with dimensions: 55cm X 40cm X
                      24cm, (all inclusive - Carry on purse/Handbag or Laptop,
                      Trolley Bag or Backpack)
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Allowable 7KG Cabin Baggage with dimensions: 55cm X 40cm X
                      24cm, (all inclusive - Carry on purse/Handbag or Laptop,
                      Trolley Bag or Backpack)
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Allowable 7KG Cabin Baggage with dimensions: 55cm X 40cm X
                      24cm, (all inclusive - Carry on purse/Handbag or Laptop,
                      Trolley Bag or Backpack)
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Checked Baggage Allowance
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      0 KG checked baggage included in this fare. Passengers can
                      purchase Excess baggage weight in KG depending on their
                      need.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      1 item, not weighing more than 15kg checked baggage
                      allowance is included in this fare product class.
                      Passengers can purchase additional Excess baggage weight
                      in KG depending on their need.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      1 item, not weighing more than 23kg checked baggage
                      allowance is included in this fare product class.
                      Passengers can purchase additional Excess baggage weight
                      in KG depending on their need.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Infant Baggage Allowance
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Passengers with infants are allowed 1 item, not weighing
                      more than 5kg checked baggage for their infants.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Passengers with infants are allowed 1 item, not weighing
                      more than 5kg checked baggage for their infants.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Passengers with infants are allowed 1 item, not weighing
                      more than 5kg checked baggage for their infants.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      WEB Check-in and Seat Selection
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      WEB Check-in is open within 24 hours to 3 hours before
                      scheduled departure time. There is an applicable SEAT FEE
                      for any preference seat selected. Otherwise, seat will be
                      AUTO ASSIGNED at the Airport for passengers in this fare
                      category.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      WEB Check-in is open within 7 days to 3 hours before
                      scheduled departure time. This Fare is entitled to select
                      any STANDARD SEAT in the Aircraft for free. There is an
                      applicable SEAT FEE for any preference seat selected other
                      than a STANDARD SEAT.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      WEB Check-in is open within 7 days to 3 hours before
                      scheduled departure time. This Fare is entitled to select
                      ANY SEAT in the Aircraft for free.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Airport Check-in
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      An Airport Transactional Fee is applicable for Check-in
                      and obtaining Boarding Pass at the Airport desk.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      An Airport Transactional Fee is applicable for Check-in
                      and obtaining Boarding Pass at the Airport desk.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Airport Check-in to obtain boarding pass is available at
                      no extra Airport Check-in Fee for this fare product class,
                      although we encourage you to Check-in on the WEB.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Seat Selection at Airport
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Seat Fee will apply for selection of any preference seat.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Seat fee will apply for selection of any preference seat
                      other than a STANDARD SEAT.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      ANY SEAT selected for this fare class is also free at the
                      Airport.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Inflight Meals
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      To be purchased through our website at the point of
                      booking to 3 hours before flight departure time.
                      (CURRENTLY NOT AVAILABLE DUE TO COVID 19 PROTOCOL)
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      To be purchased through our website at the point of
                      booking to 3 hours before flight departure time.
                      (CURRENTLY NOT AVAILABLE DUE TO COVID 19 PROTOCOL)
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      To be purchased through our website at the point of
                      booking to 3 hours before flight departure time.
                      (CURRENTLY NOT AVAILABLE DUE TO COVID 19 PROTOCOL)
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Voluntary Cancellations and Refund
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Non-refundable
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Non-refundable
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Refundable into a Credit Shell within Green Africa System
                      and can be used to pay for another booking.
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Name Change
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Not allowed
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Not allowed
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Not allowed
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      Voluntary Route Change
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Not allowed
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Not allowed
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Not allowed
                    </Td>
                  </Tr>
                  <Tr borderBottom="1px solid" borderColor="body">
                    <Th
                      scope="row"
                      rowSpan={1}
                      colSpan={1}
                      className="td-rowspan"
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                    >
                      No Show or Missed Departure
                    </Th>
                    <Td
                      _before={{
                        content: "'Saver'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Saver"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Customer is eligible to reschedule within six months from
                      the date of the missed flight. A missed departure fee and
                      possible fare difference may be applicable.
                    </Td>
                    <Td
                      _before={{
                        content: "'Classic'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Classic"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Customer is eligible to reschedule within six months from
                      the date of the missed flight. A missed departure fee and
                      possible fare difference may be applicable.
                    </Td>
                    <Td
                      _before={{
                        content: "'Flex'",
                        flex: "0 0 40%",
                        textAlign: "left",
                        color: "heading",
                        display: { base: "inherit", md: "none" },
                        fontWeight: "semibold",
                        letterSpacing: "wider",
                        textTransform: "uppercase",
                        fontSize: "xs",
                      }}
                      borderBottom="1px solid"
                      borderColor="body"
                      position="relative"
                      verticalAlign="middle"
                      display={{ base: "flex", md: "table-cell" }}
                      alignItems={{ base: "flex-start", md: "unset" }}
                      justifyContent={{ base: "flex-start", md: "unset" }}
                      data-th="Flex"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Customer is eligible to reschedule within six months from
                      the date of the missed flight. A missed departure fee and
                      possible fare difference may be applicable.
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default FareCategories;
