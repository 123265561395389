export const getDates = (date?: Date | string, size = 7) => {
  if (!date) return [];

  const dateArr = Array.from(Array(size).keys()).map(index => {
    const parsedDate = new Date(date);
    const middleIndex = Math.floor(size / 2);

    if (index < middleIndex)
      parsedDate.setDate(parsedDate.getDate() + (index - middleIndex));
    if (index > middleIndex)
      parsedDate.setDate(parsedDate.getDate() + (index - middleIndex));

    return { date: parsedDate, price: `₦${(index + 1) * 10},500` };
  });
  return dateArr;
};

export const formatDate = (date?: Date | string) => {
  if (!date) return "";
  return Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
  }).format(new Date(date));
};
