import { Box, Flex, Icon, Input, InputProps } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { IconType } from "react-icons/lib";
import "./datepicker.css";

interface Props<Field extends FieldValues> extends InputProps {
  icon: IconType;
  hookform: UseFormReturn<Field>;
  name: Path<Field>;
}

const FlightDate = <Field extends FieldValues>(props: Props<Field>) => {
  const { name, hookform, icon, ...rest } = props;

  return (
    <Flex
      h="full"
      align="center"
      flex={{
        base: "0 0 100%",
        sm: "0 0 calc(50% - (2rem + 0.8px))",
        lg: "1",
      }}
      gap={4}
    >
      <Box pointerEvents="none">
        <Icon as={icon} color="grey" fontSize="2rem" />
      </Box>
      <Controller
        name={name}
        control={hookform.control}
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            selected={value}
            onChange={onChange}
            minDate={new Date()}
            placeholderText="Date"
            customInput={
              <Input
                minH="3.5rem"
                variant="unstyled"
                autoComplete="off"
                _placeholder={{
                  color: !!hookform.formState.errors[name] ? "red.300" : "grey",
                }}
                {...rest}
              />
            }
            isClearable={false}
            showPopperArrow={false}
          />
        )}
      />
    </Flex>
  );
};

export default FlightDate;
