import { RouterLink } from "@/components";
import { Opening } from "@/types";
import { Box, Flex, FlexProps, Heading, Tag, Text } from "@chakra-ui/react";

interface Props extends FlexProps {
  opening: Opening;
}

const OpeningCard: React.FC<Props> = ({ opening, ...props }) => {
  return (
    <RouterLink to="#" _hover={{}}>
      <Flex
        border="1px solid"
        borderColor="secondary.main"
        shadow="md"
        p={4}
        rounded="md"
        justify="space-between"
        align={{ sm: "center" }}
        flexDir={{ base: "column", sm: "row" }}
        mb={{ base: "1rem", sm: 0 }}
        fontSize="xs"
        {...props}
      >
        <Box>
          <Heading mb={1} size="md" color="heading">
            {opening.title}
          </Heading>
          <Tag colorScheme="secondary" mb={1} fontSize="xs">
            {opening.department}
          </Tag>
          <Text>{opening.level}</Text>
        </Box>
        <Box textAlign="right">
          <Text mb={1}>{opening.location}</Text>
          <Tag mb={1} fontSize="xs">
            {opening.type}
          </Tag>
          <Text>Posted on {opening.datePosted}</Text>
        </Box>
      </Flex>
    </RouterLink>
  );
};

export default OpeningCard;
