import helmetConfig from "@/lib/helmet";
import { Helmet } from "react-helmet-async";

interface Props {
  title?: string;
}

const SEO: React.FC<Props> = ({ title }) => {
  return (
    <Helmet {...helmetConfig}>
      <title>{title}</title>
      <link rel="canonical" href={window.location.origin} />
      <meta property="og:title" content={title} />
    </Helmet>
  );
};

export default SEO;
