import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { FaGlobe } from "react-icons/fa";
import Container from "../common/Container";
import RouterLink from "../common/RouterLink";

interface Props {}

const ROUTES = [
  {
    heading: "Frequently visited pages",
    children: [
      { label: "View & amend flights", href: "/view-and-amend-flights" },
      // { label: "Flight offers worldwide", href: "#" },
      { label: "Baggage", href: "/baggage" },
      { label: "Check-in", href: "/check-in" },
      { label: "At the airport", href: "/at-the-airport" },
      { label: "In-flight services at a glance", href: "/in-flight-services" },
    ],
  },
  {
    heading: "Customer services",
    children: [
      { label: "Offices", href: "/support/offices" },
      { label: "Contacts", href: "/support/contacts" },
      { label: "Privacy Policy", href: "/support/privacy-policy" },
      {
        label: "Terms and Conditions",
        href: "/support/terms-and-conditions",
      },
    ],
  },
  {
    heading: "Information",
    children: [
      { label: "Travel Documents", href: "/information/travel-documents" },
      { label: "Our Fleet", href: "/information/our-fleet" },
      { label: "FAQ", href: "/information/faq" },
      { label: "Fare Categories", href: "/information/fare-categories" },
    ],
  },
  {
    heading: "Corporate Customers",
    children: [
      {
        label: "For travel agents",
        href: "/corporate-customers/travel-agents",
      },
      {
        label: "For corporate customers",
        href: "/corporate-customers/corporate-customers",
      },
      {
        label: "Corporate bonus program",
        href: "/corporate-customers/corporate-bonus-program",
      },
    ],
  },
];

const Footer: React.FC<Props> = () => {
  return (
    <Box as="footer" py={8}>
      <Flex direction="column" gap={{ base: 10, sm: 8 }}>
        <Container>
          <Grid
            templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
            gap={{ base: 6, md: 4 }}
          >
            <InputGroup size="md">
              <Input
                variant="flushed"
                pr="2.75rem"
                placeholder="Search for topic"
              />
              <InputRightElement width="2.75rem">
                <IconButton
                  variant="ghost"
                  aria-label="search"
                  icon={<Icon as={BsSearch} />}
                  h="1.75rem"
                  size="sm"
                />
              </InputRightElement>
            </InputGroup>
            <GridItem display={{ base: "none", md: "block" }} colSpan={2} />
            {/* <Flex align="center" gap={4} color="grey">
              <Icon as={FaGlobe} fontSize="1.5rem" />
              <Text whiteSpace="nowrap">United Kingdom | English</Text>
            </Flex> */}
          </Grid>
        </Container>
        <Container>
          <Grid
            templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
            gap={{ base: 10, md: 4 }}
          >
            {ROUTES.map((route, index) => (
              <Flex gap={2} direction="column" key={index}>
                <Heading
                  as="h6"
                  color="heading"
                  fontWeight="semibold"
                  size="sm"
                  mb={2}
                >
                  {route.heading}
                </Heading>
                {route.children.map((child, index) => (
                  <RouterLink key={index} to={child.href}>
                    {child.label}
                  </RouterLink>
                ))}
              </Flex>
            ))}
          </Grid>
        </Container>
        <Divider borderColor="grey" />
        <Container>
          <Flex
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
            align="center"
            gap={4}
          >
            <RouterLink to="/">
              <Image src="/images/logo.png" h={10} />
            </RouterLink>
            <Text>
              @ {new Date().getFullYear()} Huriya Air. All Rights Reserved.
            </Text>
          </Flex>
        </Container>
      </Flex>
    </Box>
  );
};

export default Footer;
