import { Container, FadeUp, GrowRight } from "@/components";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import FlightForm from "./FlightForm";

interface Props {}

const Hero: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <Container
      pt={{ base: "5rem", md: "7rem" }}
      pb={{ base: "7rem", md: "12rem" }}
      position="relative"
    >
      <Flex
        px={4}
        position="absolute"
        zIndex="-1"
        inset="0"
        align="end"
        bottom={{ base: "-10%", lg: "-15%" }}
        justifyContent="center"
      >
        <FadeUp w={{ base: "full", sm: "80%", lg: "70%" }}>
          <Image objectFit="contain" w="full" src="/images/gold-plane.png" />
        </FadeUp>
      </Flex>
      <Flex textAlign="center" direction="column" gap={10}>
        <FadeUp>
          <Heading color="heading" as="h1" size="3xl">
            Say yes to the world
          </Heading>
        </FadeUp>
        <Box fontSize={{ base: "xl", md: "2xl" }}>
          <FadeUp>
            <Text>Find your flight and explore the world with us.</Text>
          </FadeUp>
          <FadeUp>
            <Text>We will take care of the rest.</Text>
          </FadeUp>
          <Flex justify="center" mt={6}>
            <Box w={16}>
              <GrowRight>
                <Box h={1} w="full" bgColor="secondary.main" />
              </GrowRight>
            </Box>
          </Flex>
        </Box>
        <FadeUp>
          <FlightForm callback={() => navigate("/booking/select")} />
        </FadeUp>
      </Flex>
    </Container>
  );
};

export default Hero;
