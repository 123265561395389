import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IconType } from "react-icons/lib";
interface Props {
  icon: IconType;
  name: string;
  hookform: UseFormReturn<any>;
}

const PassengerInput = ({ icon, name, hookform }: Props) => {
  const adult = hookform.watch(name)?.adult;
  const child = hookform.watch(name)?.child;
  const infant = hookform.watch(name)?.infant;
  const total = adult + child;

  const setValue = (value: number, accessor: string) => {
    hookform.setValue(name, { ...hookform.getValues(name), [accessor]: value });
  };

  const handleAdultIncrement = (count: number) => {
    setValue(count, "adult");
  };

  const handleAdultDecrement = (count: number) => {
    if (infant === adult) handleInfantDecrement(count);
    setValue(count, "adult");
  };

  const handleChildIncrement = (count: number) => {
    setValue(count, "child");
  };

  const handleChildDecrement = (count: number) => {
    setValue(count, "child");
  };

  const handleInfantIncrement = (count: number) => {
    setValue(count, "infant");
  };

  const handleInfantDecrement = (count: number) => {
    setValue(count, "infant");
  };

  const isAdultIncrementDisabled = total === 9;
  const isAdultDecrementDisabled = adult === 1;

  const isChildIncrementDisabled = total === 9;
  const isChildDecrementDisabled = child === 0;

  const isInfantIncrementDisabled = infant === adult;
  const isInfantDecrementDisabled = infant === 0;

  return (
    <Box
      flex={{
        base: "0 0 100%",
        sm: "0 0 calc(50% - (2rem + 0.8px))",
        lg: "1",
      }}
    >
      <Popover>
        <PopoverTrigger>
          <Button
            w="full"
            display="inline-flex"
            alignItems="center"
            variant="unstyled"
            bgColor="white"
            h="full"
            textAlign="left"
            gap={4}
          >
            <Icon as={icon} color="grey" fontSize="2rem" />
            <Text flex="1 1 100%">{total} Passengers</Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody p={4} overflow="auto">
            <PassengerCounter
              label="Adult"
              age="(>11 years)"
              count={adult}
              isIncrementDisabled={isAdultIncrementDisabled}
              isDecrementDisabled={isAdultDecrementDisabled}
              onIncrement={handleAdultIncrement}
              onDecrement={handleAdultDecrement}
            />
            <PassengerCounter
              label="Child"
              age="(2-11 years)"
              count={child}
              isIncrementDisabled={isChildIncrementDisabled}
              isDecrementDisabled={isChildDecrementDisabled}
              onIncrement={handleChildIncrement}
              onDecrement={handleChildDecrement}
            />
            <PassengerCounter
              label="Infant"
              age="(<2 years)"
              count={infant}
              isIncrementDisabled={isInfantIncrementDisabled}
              isDecrementDisabled={isInfantDecrementDisabled}
              onIncrement={handleInfantIncrement}
              onDecrement={handleInfantDecrement}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

interface PassengerCounterProps {
  label: string;
  age: string;
  count: number;
  isIncrementDisabled: boolean;
  isDecrementDisabled: boolean;
  onIncrement: (count: number) => void;
  onDecrement: (count: number) => void;
}

const PassengerCounter = (props: PassengerCounterProps) => {
  const {
    label,
    age,
    count,
    isIncrementDisabled,
    isDecrementDisabled,
    onIncrement,
    onDecrement,
  } = props;

  return (
    <Flex justify="space-between" mb={4}>
      <Box>
        <Text fontSize="lg" fontWeight="medium" color="heading">
          {label}
        </Text>
        <Text fontSize="xs">{age}</Text>
      </Box>
      <Flex align="center" gap={4}>
        <IconButton
          size="sm"
          aria-label="-1"
          borderRadius="full"
          bgColor="white"
          variant="outline"
          icon={<Icon as={FaMinus} />}
          isDisabled={isDecrementDisabled}
          onClick={() => onDecrement(count - 1)}
        />
        <Text fontSize="lg" fontWeight="medium" color="heading">
          {count}
        </Text>
        <IconButton
          size="sm"
          aria-label="+1"
          borderRadius="full"
          bgColor="white"
          variant="outline"
          icon={<Icon as={FaPlus} />}
          isDisabled={isIncrementDisabled}
          onClick={() => onIncrement(count + 1)}
        />
      </Flex>
    </Flex>
  );
};

export default PassengerInput;
