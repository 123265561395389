import { createBrowserRouter, Navigate } from "react-router-dom";
import Blank from "./blank";
import BookingLayout from "./booking";
import Passengers from "./booking/passengers";
import Payment from "./booking/payment";
import SeatMap from "./booking/seat-map";
import SelectFlight from "./booking/select";
import Services from "./booking/services";
import Careers from "./careers";
import Destinations from "./destinations";
import City from "./destinations/city";
import ErrorPage from "./error-page";
import Home from "./home";
import FAQ from "./information/faq";
import OurFleet from "./information/our-fleet";
import TravelDocuments from "./information/travel-documents";
import Root from "./root";
import Contacts from "./support/contacts";
import FareCategories from "./support/fare-categories";
import Offices from "./support/offices";
import PrivacyPolicy from "./support/privacy-policy";
import TermsAndConditions from "./support/terms-and-conditions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "destinations", element: <Destinations /> },
      { path: "destinations/:city", element: <City /> },
      { path: "careers", element: <Careers /> },
      {
        path: "/view-and-amend-flights",
        element: <Blank title="View & amend flights" />,
      },
      { path: "/baggage", element: <Blank title="Baggage" /> },
      { path: "/check-in", element: <Blank title="Check-in" /> },
      { path: "/at-the-airport", element: <Blank title="At the airport" /> },
      {
        path: "/in-flight-services",
        element: <Blank title="In-flight services at a glance" />,
      },
      {
        path: "/corporate-customers/travel-agents",
        element: <Blank title="For travel agents" />,
      },
      {
        path: "/corporate-customers/corporate-customers",
        element: <Blank title="For corporate customers" />,
      },
      {
        path: "/corporate-customers/corporate-bonus-program",
        element: <Blank title="Corporate bonus program" />,
      },
      { path: "/book-and-manage", element: <Blank title="Book & manage" /> },
      {
        path: "/prepare-for-travel",
        element: <Blank title="Prepare for travel" />,
      },
      {
        path: "/during-your-trip",
        element: <Blank title="During your trip" />,
      },
      { path: "/discover", element: <Blank title="Discover" /> },
      {
        path: "booking",
        element: <BookingLayout />,
        children: [
          { index: true, element: <Navigate to="select" /> },
          { path: "select", element: <SelectFlight /> },
          { path: "passengers", element: <Passengers /> },
          { path: "services", element: <Services /> },
          { path: "seat-map", element: <SeatMap /> },
          { path: "payment", element: <Payment /> },
          { path: "confirmation", element: <Payment /> },
        ],
      },
      {
        path: "information",
        children: [
          { index: true, element: <Navigate to="travel-documents" /> },
          { path: "travel-documents", element: <TravelDocuments /> },
          { path: "our-fleet", element: <OurFleet /> },
          { path: "faq", element: <FAQ /> },
          { path: "fare-categories", element: <FareCategories /> },
        ],
      },
      {
        path: "support",
        children: [
          { index: true, element: <Navigate to="offices" /> },
          { path: "offices", element: <Offices /> },
          { path: "contacts", element: <Contacts /> },
          { path: "privacy-policy", element: <PrivacyPolicy /> },
          { path: "terms-and-conditions", element: <TermsAndConditions /> },
        ],
      },
    ],
  },
]);

export default router;
