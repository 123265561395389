import { Container, SEO } from "@/components";
import { PLACES } from "@/constants";
import FlightForm from "@/routes/home/FlightForm";
import { Box, Grid, Heading, Image, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import DestinationCard from "../DestinationCard";

interface Props {}

const City: React.FC<Props> = () => {
  const { city } = useParams();
  const navigate = useNavigate();
  const cityAirport = useMemo(
    () => PLACES.find(place => slugify(place.name, { lower: true }) === city),
    [city]
  );
  const otherAirports = useMemo(
    () => PLACES.filter(place => slugify(place.name, { lower: true }) !== city),
    [city]
  );
  const date = new Date();
  date.setDate(date.getDate() + 3);

  return (
    <Box bgColor="backgroundAlt" isolation="isolate">
      <SEO title={`Book a flight to ${cityAirport?.name ?? ""}`} />
      <Container py={10}>
        <Box mb={6}>
          <Heading as="h1" size="lg" color="heading" mb={4}>
            {cityAirport?.name}
          </Heading>
          <Image
            src={cityAirport?.image}
            alt={cityAirport?.name}
            h={{ base: "200px", md: "350px" }}
            w="full"
            objectFit="cover"
            borderRadius="sm"
          />
        </Box>
        <FlightForm
          defaultValues={{
            departureAirport: otherAirports[0],
            arrivalAirport: cityAirport,
            date,
            passengers: { adult: 1, child: 0, infant: 0 },
          }}
          callback={() => navigate("/booking/select")}
          shadow="none"
          mb={10}
        />
        <Text whiteSpace="pre-wrap" mb={10}>
          {cityAirport?.description}
        </Text>
        <Heading as="h2" size="md" fontWeight="medium" color="heading" mb={6}>
          Other Destinations
        </Heading>
        <Grid templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr" }} gap={8}>
          {otherAirports.slice(0, 3).map((place, index) => (
            <DestinationCard key={index} place={place} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default City;
