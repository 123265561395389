import { Container, SEO } from "@/components";
import { Box, Heading, Text } from "@chakra-ui/react";

interface Props {}

const Contacts: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Contacts" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Contacts
        </Heading>

        <Box>
          <Text>
            Contact Center: +2340000000000
            <br />
            <br />
            Huriya on Twitter: @huriya-air
            <br />
            <br />
            For bookings and enquiries: bookings@huriya.com
            <br />
            <br />
            General information: info@huriya.com
            <br />
            <br />
            Media enquiries: media@huriya.com
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Contacts;
