import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
} from "@chakra-ui/react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

interface Props<Field extends FieldValues> extends SelectProps {
  hookform: UseFormReturn<Field>;
  label: string;
  name: Path<Field>;
}

const FormSelect = <Field extends FieldValues>(props: Props<Field>) => {
  const { hookform, label, name, children, isRequired, ...rest } = props;

  return (
    <FormControl
      isInvalid={!!hookform.formState.errors[name]}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select id={name} {...hookform.register(name)} {...rest}>
        {children}
      </Select>
      <FormErrorMessage>
        {hookform.formState.errors[name]?.message as string}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormSelect;
