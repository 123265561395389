import { BoxProps } from "@chakra-ui/react";
import { Variants, useInView, useReducedMotion } from "framer-motion";
import { useRef } from "react";
import MotionBox from "./MotionBox";

const GrowUp: React.FC<BoxProps> = ({ children, ...props }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const shouldReduceMotion = useReducedMotion();

  const variants: Variants = {
    visible: { height: "100%", transition: { duration: 0.4 } },
    hidden: { height: shouldReduceMotion ? "100%" : 0 },
  };

  return (
    <MotionBox
      layout
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      initial="hidden"
      exit="hidden"
      ref={ref}
      {...props}
    >
      {children}
    </MotionBox>
  );
};

export default GrowUp;
