import { Container, SEO } from "@/components";
import { FAQs } from "@/constants";
import { Accordion, Box, Heading, Text } from "@chakra-ui/react";
import FAQAccordion from "./FAQAccordion";

interface Props {}

const FAQ: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Frequently Asked Questions" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Frequently Asked Questions
        </Heading>
        <Box maxW="800px">
          {FAQs.map(({ heading, body }, index) => (
            <Box _notLast={{ mb: 10 }} key={index}>
              <Text
                as="h2"
                color="heading"
                fontWeight="medium"
                fontSize={{ base: "xl", sm: "2xl" }}
                mb={4}
              >
                {heading}
              </Text>
              <Accordion allowToggle>
                {body.map(({ question, answer }, index) => (
                  <FAQAccordion
                    key={index}
                    question={question}
                    answer={answer}
                    _notLast={{ mb: 6 }}
                  />
                ))}
              </Accordion>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;
