import { SEO } from "@/components";
import Hero from "./Hero";
import PaymentOptions from "./PaymentOptions";
import PopularDestinations from "./PopularDestinations";
import Services from "./Services";
import StayConnected from "./StayConnected";

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      <Hero />
      <PopularDestinations />
      <Services />
      <PaymentOptions />
      <StayConnected />
    </>
  );
};

export default Home;
