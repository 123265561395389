import { Container } from "@/components";
import { formatDate, getDates } from "@/lib/utils";
import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  FaChevronLeft,
  FaChevronRight,
  FaPlaneDeparture,
} from "react-icons/fa";

const BookingDates = () => {
  const [isLagerThan80] = useMediaQuery("(min-width: 80em)");
  const [isLagerThan48] = useMediaQuery("(min-width: 48em)");
  const { booking, setBooking } = useGlobalStore();
  const [currentDate, setCurrentDate] = useState<Date>(
    new Date(booking?.date ?? "")
  );

  const bookingDate = new Date(booking?.date || "");
  const size = isLagerThan80 ? 7 : isLagerThan48 ? 5 : 3;
  const dates = getDates(currentDate, size);

  const isActive = (date: Date) => date.getTime() === bookingDate.getTime();
  const isAfter = (index: number) =>
    isLagerThan80
      ? index === 3 || index === 6
      : isLagerThan48
      ? index === 2 || index === 4
      : true;

  return (
    <Box py={8}>
      <Container py={4}>
        <Text as="h2" fontSize="xl" mb={2}>
          Departure
        </Text>
        <Text
          as="h3"
          fontSize="4xl"
          color="heading"
          fontWeight="bold"
          display="inline-flex"
          alignItems="center"
          gap={2}
        >
          <Icon color="secondary.main" as={FaPlaneDeparture} />
          {booking?.departureAirport.name} to {booking?.arrivalAirport.name}
        </Text>
      </Container>
      <Box bgColor="white">
        <Container>
          <Flex justify="space-between" align="center" position="relative">
            <IconButton
              size={{ base: "sm", md: "md" }}
              borderRadius="full"
              minH={{ base: "2.375rem", md: "3rem" }}
              aria-label="previous dates"
              variant="ghost"
              icon={<Icon as={FaChevronLeft} />}
              position="absolute"
              zIndex={10}
              left={-4}
              top="50%"
              transform="translateY(-50%)"
              onClick={() =>
                setCurrentDate(prev => {
                  const date = new Date(prev);
                  date.setDate(date.getDate() - Math.floor(size / 2));
                  return date;
                })
              }
            />
            <Flex as="ul" flex="1 1 100%">
              {dates.map(({ date, price }, index) => (
                <Box
                  key={index}
                  as="li"
                  listStyleType="none"
                  flex="1"
                  position="relative"
                  _after={{
                    content: "''",
                    display: isAfter(index) ? "none" : "block",
                    h: "70%",
                    w: "0.125rem",
                    bgColor: "gray.100",
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <Button
                    w="full"
                    flexDirection="column"
                    fontWeight="normal"
                    variant={isActive(date) ? "solid" : "ghost"}
                    h="6rem"
                    gap={2}
                    colorScheme={isActive(date) ? "secondary" : "gray"}
                    onClick={() => booking && setBooking({ ...booking, date })}
                  >
                    <Text as="span">{formatDate(date)}</Text>
                    <Text as="span" fontSize={{ base: "md", md: "2xl" }}>
                      {price}
                    </Text>
                  </Button>
                </Box>
              ))}
            </Flex>
            <IconButton
              size={{ base: "sm", md: "md" }}
              borderRadius="full"
              minH={{ base: "2.375rem", md: "3rem" }}
              aria-label="next dates"
              variant="ghost"
              icon={<Icon as={FaChevronRight} />}
              position="absolute"
              zIndex={10}
              right={-4}
              top="50%"
              transform="translateY(-50%)"
              onClick={() =>
                setCurrentDate(prev => {
                  const date = new Date(prev);
                  date.setDate(date.getDate() + Math.floor(size / 2));
                  return date;
                })
              }
            />
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default BookingDates;
