import { FadeUp } from "@/components";
import { DESTINATIONS } from "@/constants";
import { Box, ButtonGroup, Icon, IconButton } from "@chakra-ui/react";
import { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import FlightsCard from "./FlightsCard";

interface Props {}

const settings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FlightsSlider: React.FC<Props> = () => {
  const sliderRef = useRef<Slider>(null);

  return (
    <Box>
      <Box minH="20rem" mb={6}>
        <Slider {...settings} ref={sliderRef}>
          {DESTINATIONS.map((destination, index) => (
            <FlightsCard key={index} {...destination} />
          ))}
        </Slider>
      </Box>
      <FadeUp>
        <ButtonGroup spacing={6}>
          <IconButton
            boxShadow="md"
            aria-label="previous-slide"
            borderRadius="full"
            bgColor="white"
            icon={<Icon as={FaChevronLeft} />}
            onClick={() => sliderRef.current?.slickPrev()}
          />
          <IconButton
            boxShadow="md"
            aria-label="next-slide"
            borderRadius="full"
            bgColor="white"
            icon={<Icon as={FaChevronRight} />}
            onClick={() => sliderRef.current?.slickNext()}
          />
        </ButtonGroup>
      </FadeUp>
    </Box>
  );
};

export default FlightsSlider;
