import { baseTheme, extendTheme, ThemeOverride } from "@chakra-ui/react";
import Button from "./components/button";

export const overrides: ThemeOverride = {
  breakpoints: {
    base: "0em",
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  },
  fonts: {
    heading: `'Inter Variable', ${baseTheme.fonts.heading}`,
    body: `'Inter Variable', ${baseTheme.fonts.body}`,
  },
  colors: {
    body: "#6f7184",
    heading: "#191d3a",
    background: "#fcfcfc",
    backgroundAlt: "#eeeff1",
    grey: "#c5c4cf",
    primary: {
      main: "#d3ae6e",
      50: "#fdf4e1",
      100: "#f6efe2",
      200: "#eddfc5",
      300: "#e4cea8",
      400: "#dcbe8c",
      500: "#d3ae6e",
      600: "#c4923d",
      700: "#936e2d",
      800: "#62491e",
      900: "#31250f",
    },
    secondary: {
      main: "#006634",
      50: "#ddfff1",
      100: "#adffd8",
      200: "#5cffb0",
      300: "#0aff89",
      400: "#00b85f",
      500: "#006634",
      600: "#00522a",
      700: "#003d20",
      800: "#002915",
      900: "#00140b",
    },
  },
  styles: {
    global: {
      html: { scrollBehavior: "smooth" },
      body: {
        bg: "#fcfcfc",
        color: "#6f7184",
      },
    },
  },
  components: { Button },
};

export default extendTheme(overrides);
