import { CircleIcon, Container, SEO } from "@/components";
import { Box, Heading, List, ListIcon, ListItem, Text } from "@chakra-ui/react";

interface Props {}

const TravelDocuments: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Travel Documents" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Travel Documents
        </Heading>
        <Box maxW="800px">
          <Text mb={4}>
            Passengers planning to travel on Huriya Air must have in their
            possession any of these documents which will be checked before
            boarding:
          </Text>
          <List mb={4}>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CircleIcon} color="secondary.main" />
              Driver's License
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CircleIcon} color="secondary.main" />
              International passport
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CircleIcon} color="secondary.main" />
              National Identification Card
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CircleIcon} color="secondary.main" />
              Permanent Voter Card
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={CircleIcon} color="secondary.main" />
              Work/Employment Identification Card
            </ListItem>
          </List>
          <Text>
            It is your responsibility to ensure details on any form of
            identification matches the details on the booking confirmation. It
            is also your responsibility to ensure all forms of identification
            are valid. Huriya Air will not provide you with a refund of ticket,
            or bear any cost associated with the refusal to board due to invalid
            identification
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default TravelDocuments;
