import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

interface Props {
  heading: string;
  body: string;
  icon: string;
}

const ServiceItem: React.FC<Props> = ({ heading, body, icon }) => {
  return (
    <Flex gap={4}>
      <Image boxSize="2rem" objectFit="contain" src={icon} alt="icon" />
      <Box>
        <Heading color="heading" as="h5" size="sm" mb={3}>
          {heading}
        </Heading>
        <Text>{body}</Text>
      </Box>
    </Flex>
  );
};

export default ServiceItem;
