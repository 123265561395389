import { Container, SEO } from "@/components";
import { PLACES } from "@/constants";
import { Box, Grid, Heading } from "@chakra-ui/react";
import DestinationCard from "./DestinationCard";

interface Props {}

const Destinations: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt" isolation="isolate">
      <SEO title="Destinations" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Destinations
        </Heading>

        <Grid templateColumns={{ sm: "1fr 1fr" }} gap={8}>
          {PLACES.map((place, index) => (
            <DestinationCard key={index} place={place} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Destinations;
