import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import BookingCard from "./BookingCard";
import FlightDetailsPopover from "./FlightDetailsPopover";

interface Props {
  index: number;
}

const CARDS = [
  {
    title: "Saver",
    price: "₦68,500",
    perks: ["Flight", "7kg hand luggage 55 x 40 x 24 cm"],
  },
  {
    title: "Classic",
    price: "₦75,500",
    perks: [
      "Flight",
      "7kg hand luggage 55 x 40 x 24 cm",
      "1 item, not weighing more than 15kg check-in luggage",
      "Free standard seat",
    ],
  },
  {
    title: "Flex",
    price: "₦84,500",
    perks: [
      "Flight",
      "7kg hand luggage 55 x 40 x 24 cm",
      "1 item, not weighing more than 23kg check-in luggage",
      "Free Seat (All seat zones)",
    ],
  },
];

const BookingClasses = ({ index }: Props) => {
  const { isOpen, onToggle } = useDisclosure();
  const booking = useGlobalStore(state => state.booking);

  return (
    <Box
      bgColor="white"
      borderRadius="xl"
      py={8}
      px={{ base: 4, sm: 6 }}
      mb={6}
    >
      <Flex
        justify="space-between"
        direction={{ base: "column", md: "row" }}
        gap={10}
      >
        <Flex justify="space-between" gap={6} align="center" flex="1 1 100%">
          <Box>
            <Text fontSize="3xl" fontWeight="bold">
              {`${1 * index + 10}:05`}
            </Text>
            <Text>{booking?.departureAirport.code}</Text>
          </Box>
          <Flex direction="column" align="center" flex="1 1 100%">
            <FlightDetailsPopover
              departureTime={`${1 * index + 10}:05`}
              arrivalTime={`${1 * index + 13}:15`}
            />
            <Box mb={4} bgColor="grey" h="2px" w="full" />
            <Text>1h 10m</Text>
          </Flex>
          <Box textAlign="right">
            <Text fontSize="3xl" fontWeight="bold">
              {`${1 * index + 13}:15`}
            </Text>
            <Text>{booking?.arrivalAirport.code}</Text>
          </Box>
        </Flex>
        <Button
          colorScheme={isOpen ? "gray" : "primary"}
          size="lg"
          rightIcon={<Icon as={isOpen ? FaChevronUp : FaChevronDown} />}
          px="3rem"
          py="2rem"
          onClick={onToggle}
          minW="13.5rem"
        >
          {isOpen ? "Close" : "From N68,500"}
        </Button>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Grid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
          gap={10}
          py={{ base: 8, md: 10 }}
          px={{ base: 4, md: 10 }}
        >
          {CARDS.map((card, index) => (
            <BookingCard key={index} {...card} />
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default BookingClasses;
