import { Container, SEO } from "@/components";
import { Box, Heading } from "@chakra-ui/react";

interface Props {
  title: string;
}

const Blank: React.FC<Props> = ({ title }) => {
  return (
    <Box bgColor="backgroundAlt" isolation="isolate">
      <SEO title="Destinations" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          {title}
        </Heading>
      </Container>
    </Box>
  );
};

export default Blank;
