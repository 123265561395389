import { Container, FadeUp } from "@/components";
import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import ServiceItem from "./ServiceItem";

const SERVICES = [
  {
    mainHeading: "Why book on Huriya Air.",
    content: [
      {
        heading: "No hidden costs",
        body: "Your save yourself the booking fees and always obtain the best-value flights",
        icon: "/images/atm-card.png",
      },
      {
        heading: "More comfort",
        body: "Create your trips flexibly and to suit your own individual preferences; whether it's last-minute changes, a second suitcase or a window seat.",
        icon: "/images/pillows.png",
      },
    ],
  },
  {
    mainHeading: "Even more comfort during flight.",
    content: [
      {
        heading: "Advance seat reservation",
        body: "Reserve your seat before you start your journey exactly according to your preferences - from a window seat to seats with more legroom.",
        icon: "/images/plane.png",
      },
      {
        heading: "Upgrade",
        body: "Treat yourself to an upgrade and enjoy the amenities of Premium Economy Class or Business Class or the luxury of First Class and arrive at your destination more relaxed.",
        icon: "/images/upgrade.png",
      },
      {
        heading: "Baggage on Light fare",
        body: "Add a first piece of baggage weight up to 23kg on your trip within Europe on the Light fare.",
        icon: "/images/suitcase.png",
      },
    ],
  },
  {
    mainHeading: "Further benefits at your arrival.",
    content: [
      {
        heading: "Travel cover",
        body: "Travel carefree and relaxed from more than 27 countries with your dependable Huriya Air travel insurance in cooperation with Travel Guard.",
        icon: "/images/comments.png",
      },
      {
        heading: "Rental cars",
        body: "Select your rental car that is suitable for you when you are booking your flight or separately afterwards and earn 500 Miles & More miles per rental.",
        icon: "/images/car.png",
      },
      {
        heading: "Hotel",
        body: "Book your accommodation via our hotel partner booking.com and secure one Miles & More award mile for every euro that you spend.",
        icon: "/images/hotel.png",
      },
      {
        heading: "Tours and activities",
        body: "Book yours and activities, which are tailored to your personal interests by our experts, before the start of your trip.",
        icon: "/images/map.png",
      },
    ],
  },
];

interface Props {}

const Services: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt" py="5rem">
      <Container>
        <Box mb={20} textAlign="center" maxW="35rem" mx="auto">
          <FadeUp>
            <Heading color="heading" mb={{ base: 6, md: 8 }}>
              All services at a glance
            </Heading>
          </FadeUp>
          <FadeUp>
            <Text>
              You can book offers conveniently from your home before you start
              your trip. You can opt for further services during your trip too.
            </Text>
          </FadeUp>
        </Box>
        <Flex direction="column" gap="5rem">
          {SERVICES.map((service, index) => (
            <Grid
              key={index}
              columnGap={{ base: "3rem", md: "5rem", lg: "7rem" }}
              rowGap={{ base: "3rem", md: "4rem" }}
              templateColumns={{ md: "19ch 1fr" }}
            >
              <FadeUp>
                <Heading
                  as="h3"
                  color="heading"
                  size="md"
                  textAlign={{ md: "right" }}
                  lineHeight="tall"
                >
                  {service.mainHeading}
                </Heading>
              </FadeUp>
              <Grid
                columnGap={{ base: "3rem", md: "5rem", lg: "7rem" }}
                rowGap={{ base: "3rem", md: "4rem" }}
                templateColumns={{ md: "1fr 1fr" }}
              >
                {service.content.map((item, index) => (
                  <FadeUp delay={0.1} custom={index} key={index}>
                    <ServiceItem {...item} />
                  </FadeUp>
                ))}
              </Grid>
            </Grid>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Services;
