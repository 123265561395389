import { Container, SEO } from "@/components";
import { Box, Heading, Text } from "@chakra-ui/react";

interface Props {}

const OurFleet: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Our Fleet" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Our Fleet
        </Heading>
        <Box maxW="800px">
          <Text mb={4}>Huriya Air operates a fleet of……….</Text>
          <Text>
            The……… is built for efficient regional operations and has been
            recognized as a leading platform to achieve cost efficiency while
            providing options to customers. The…… has a fuel burn advantage with
            20% less operating cost in its category, which in turn will enable
            Huriya Air to provide affordable fares and open new city pair
            options to customers.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default OurFleet;
