import { Container, SEO } from "@/components";
import { Box, Heading, Text } from "@chakra-ui/react";

interface Props {}

const Offices: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Offices" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Offices
        </Heading>

        <Box>
          <Heading as="h2" size="md" fontWeight="medium" color="heading" mb={2}>
            Our Head office:
          </Heading>
          <Text>Abuja, Nigeria</Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Offices;
