import { Container, FadeUp, GrowUp, RouterLink } from "@/components";
import {
  Accordion,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  FaRegCalendarCheck,
  FaRegClock,
  FaRegCreditCard,
  FaSuitcaseRolling,
} from "react-icons/fa";
import FlightAccordion from "./FlightAccordion";
import FlightsSlider from "./FlightsSlider";

const LIST = [
  { heading: "Check-in", icon: FaSuitcaseRolling },
  { heading: "Flight status", icon: FaRegClock },
  { heading: "My Bookings", icon: FaRegCalendarCheck },
  { heading: "Miles & More", icon: FaRegCreditCard },
];

interface Props {}

const PopularDestinations: React.FC<Props> = () => {
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(ref.current?.clientHeight ?? 0);
  }, []);

  return (
    <Container py="10rem">
      {/* <Accordion
        mb="7rem"
        display="grid"
        gridTemplateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
        gap={6}
        allowToggle
      >
        {LIST.map((item, index) => (
          <FadeUp custom={index} key={index}>
            <FlightAccordion {...item}></FlightAccordion>
          </FadeUp>
        ))}
      </Accordion> */}
      <Grid
        alignItems="center"
        templateColumns={{ base: "minmax(0, 1fr)", md: "1fr minmax(0, 1.5fr)" }}
        gap={{ base: "4rem", md: "4rem" }}
      >
        <Flex direction="column" gap={8}>
          <FadeUp>
            <Heading color="heading">Unscheduled Flights</Heading>
          </FadeUp>

          <Box>
            <Flex align="end" gap={4} h={`${height}px`}>
              <GrowUp>
                <Box h="full" minW={1} bgColor="primary.main" />
              </GrowUp>
              <FadeUp>
                <Text ref={ref}>
                  Travel with our exclusive unscheduled flight packages,
                  providing you with convenient and direct routes to
                  off-the-beaten-path destinations scattered across the
                  enchanting landscapes of Nigeria.
                </Text>
              </FadeUp>
            </Flex>
          </Box>
          <FadeUp>
            <RouterLink to="/destinations">
              <Button w="max-content" colorScheme="primary">
                Discover more
              </Button>
            </RouterLink>
          </FadeUp>
        </Flex>
        <FlightsSlider />
      </Grid>
    </Container>
  );
};

export default PopularDestinations;
