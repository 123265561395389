import { Container, FadeUp } from "@/components";
import {
  Box,
  Button,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaPaperPlane } from "react-icons/fa";

interface Props {}

const StayConnected: React.FC<Props> = () => {
  return (
    <Container py="7rem">
      <Box mb={10} textAlign="center" maxW="35rem" mx="auto">
        <FadeUp>
          <Heading color="heading" mb={{ base: 6, md: 8 }}>
            Stay connected
          </Heading>
        </FadeUp>
        <FadeUp>
          <Text>
            Save with out latest fares and offers. Leave us your e-mail address
            and receive information about our special offers.
          </Text>
        </FadeUp>
      </Box>
      <FadeUp>
        <Box
          p={3}
          bgColor="white"
          h="4.75rem"
          borderRadius="lg"
          maxW="40rem"
          mx="auto"
          shadow="lg"
        >
          <InputGroup h="full">
            <InputLeftElement pointerEvents="none" h="full">
              <Icon as={FaPaperPlane} color="grey" fontSize="1.75rem" />
            </InputLeftElement>
            <Input
              pl="3rem"
              pr="8rem"
              h="full"
              variant="unstyled"
              _placeholder={{ color: "grey" }}
              type="email"
              placeholder="Type your email-address"
            />
            <InputRightElement h="full" w="max-content">
              <Button colorScheme="primary" h="full" py={2} px={5}>
                Subscribe
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </FadeUp>
    </Container>
  );
};

export default StayConnected;
