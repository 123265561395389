import { Container, SEO } from "@/components";
import BookingClasses from "./BookingClasses";
import BookingDates from "./BookingDates";
import BookingDetails from "./BookingDetails";

const SelectFlight = () => {
  return (
    <>
      <SEO title="Select Flight" />
      <BookingDates />
      <Container>
        {Array.from(Array(5).keys()).map(index => (
          <BookingClasses key={index} index={index} />
        ))}
        <BookingDetails />
      </Container>
    </>
  );
};

export default SelectFlight;
