import { Container, SEO } from "@/components";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

interface Props {}

const TermsAndConditions: React.FC<Props> = () => {
  return (
    <Box bgColor="backgroundAlt">
      <SEO title="Terms and Conditions" />
      <Container py={10}>
        <Heading as="h1" size="lg" color="heading" mb={10}>
          Terms and Conditions
        </Heading>
        <Flex direction="column" gap={8} maxW="800px">
          <Flex direction="column" gap={3}>
            <Heading as="h2" color="heading" size="sm">
              ARTICLE 1 - DEFINITIONS
            </Heading>
            <Text>
              Within these conditions and except as otherwise provided for
              herein, the following terms are used with the meaning given below:
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                We, us, our
              </Text>{" "}
              - means Huriya Air
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                You, your, yourself
              </Text>{" "}
              - means any person holding a Confirmed Booking who is to be
              carried or is carried on an Aircraft, except members of the crew.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Adult
              </Text>{" "}
              - means a Customer aged twelve (12) years and over on the date of
              travel.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Agreed Stopping Places
              </Text>{" "}
              - are the places, except the place of departure and the place of
              destination, set out in your Confirmed Booking or shown in our
              timetables as scheduled stopping places on your route.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Aircraft
              </Text>{" "}
              - means an aircraft owned, operated or otherwise managed by Huriya
              Air in connection with its operations.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Airline Designator Code
              </Text>{" "}
              - is the two or three letters or the letter and number which
              identify individual Carriers in a Booking Confirmation,
              timetables, reservation system or elsewhere.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Article
              </Text>{" "}
              - means an article of these General Conditions of Carriage.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Authorized Agent
              </Text>{" "}
              - means anyone or any entity (which may include other Carriers) we
              have
            </Text>
            <Text>
              appointed to represent us in the sale of carriage by air and other
              services.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Baggage
              </Text>{" "}
              - means your personal property accompanying you on your flight,
              which, unless otherwise specified, consists of your Checked and
              Cabin Baggage unless we state otherwise.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Baggage Identification Tag
              </Text>{" "}
              - means a label issued by us and attached to your Checked Baggage
              at the check-in desk, which identifies your Checked Baggage on
              arrival at your Place of Destination.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Baggage Receipt
              </Text>{" "}
              - means the receipt given to you by us or our Authorized Agent at
              the check-in desk which identifies your Checked Baggage.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Base Fare
              </Text>{" "}
              - means the fare we will charge you for travel on our flights,
              excluding any Taxes or Service Fees.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Booking
              </Text>{" "}
              - means the reservation we hold in our systems which includes all
              details of the flights, Customers, Customer contact details,
              Services and Tariffs charged and any other payments received for
              your travel with us.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Booking Confirmation
              </Text>{" "}
              - means a document or documents we or our Authorized Agents issue
              on paper, e-mail, SMS or other electronic means. It contains the
              Customer&rsquo;s name, Customer contact details, flight and
              airline contact information, confirmation of all monies paid and
              details of the Fare Rules applicable to the Booking.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Booking Reference
              </Text>{" "}
              - means the unique alpha numeric reference we assign to you to
              identify your
            </Text>
            <Text>Booking.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Business Days
              </Text>{" "}
              - means a Monday to Friday.
            </Text>
            <Text>
              Cabin Baggage - means your baggage and personal items other than
              your Checked Baggage.
            </Text>
            <Text>Cabin Baggage is carried with you on to the Aircraft.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Cabin Baggage Allowance
              </Text>{" "}
              - is the number of bags, up to a maximum weight per bag, which
              your Fare Rules, as advised by us at time of booking and in your
              Booking Confirmation, allow you to carry with you on the Aircraft
              for no additional fees.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Carrier
              </Text>{" "}
              - means an airline offering transport under these Conditions of
              Carriage.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Carrier Regulations
              </Text>{" "}
              - means our Fare Rules, Service Fees and these Conditions of
              Carriage.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Checked baggage
              </Text>{" "}
              - is baggage which we have taken into our custody and for which we
              have issued a Baggage Identification Tag and Baggage Receipt which
              normally travels in the hold of the aircraft.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Checked baggage Allowance
              </Text>{" "}
              - is the number of bags, up to a maximum weight per bag, which are
              detailed in your Fare Rules and advised by us at time of booking
              and in your Booking Confirmation, allow you to check-in for
              carriage in the hold of our Aircraft for no additional fees.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Check-in Deadline
              </Text>{" "}
              - the time limit we have set by which you must have completed the
              check-in formalities and received your Boarding Pass.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Child
              </Text>{" "}
              - means a Customer aged between 2 and 11 years of age (inclusive)
              on the date of travel.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Conditions of Carriage
              </Text>{" "}
              - means these General Conditions of Carriage for Customers and
              Baggage.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Conditions of Contract
              </Text>{" "}
              - means those statements contained in or delivered with your
              Booking
            </Text>
            <Text>
              Confirmation, identified as such and which incorporate by
              reference, these Conditions of
            </Text>
            <Text>Carriage and Notices.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Confirmed Booking
              </Text>{" "}
              - means a Booking for which all Customer details and full payments
              have
            </Text>
            <Text>been received.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Convention
              </Text>{" "}
              - means the Convention for the Unification of Certain Rules for
              International Carriage
            </Text>
            <Text>
              by Air, 1999 (the &lsquo;Montr&eacute;al Convention&rsquo;), the
              Convention on International Interests in Mobile
            </Text>
            <Text>
              Equipment (the &lsquo;Cape Town Convention&rsquo;) or any other
              relevant Convention to regulate aviation or
            </Text>
            <Text>
              aerospace as Nigeria may elect to ratify and incorporate into its
              domestic laws.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Credit
              </Text>{" "}
              - means a value, held in our reservation system, valid for a
              maximum of one (1) year from
            </Text>
            <Text>
              issuance, which you may use as full or part payment towards a
              Booking made directly with us via
            </Text>
            <Text>
              our direct booking channels, as may vary from time to time.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Customer
              </Text>{" "}
              - means any person holding a Confirmed Booking who is carried, or
              is to be carried, on
            </Text>
            <Text>an aircraft, except members of the crew.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Damage
              </Text>{" "}
              - this includes death of, wounding of, or bodily injury to a
              Customer. It also includes
            </Text>
            <Text>
              loss, partial loss, and theft of, or other damage to Baggage
              arising out of, or in connection with
            </Text>
            <Text>
              carriage or other services incidental thereto performed by us.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Days
              </Text>{" "}
              - means calendar days including all seven days of the week.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Domestic Flights
              </Text>{" "}
              - mean Flights within Nigeria.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Events Beyond Your Control
              </Text>{" "}
              - are unusual and unforeseeable circumstances which you cannot
            </Text>
            <Text>
              control and the consequences of which you could not have avoided
              even if you had taken all due
            </Text>
            <Text>care Involuntary.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Excess Baggage
              </Text>{" "}
              - means Checked Baggage which is not included in your Cabin
              Baggage
            </Text>
            <Text>
              Allowance or your Checked Baggage Allowance, as detailed in your
              Fare Rules, which we may
            </Text>
            <Text>carry as Checked Baggage for an additional Service Fee.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Fare
              </Text>{" "}
              - means the total amount charged for travel on our Flights,
              including the Base Fare and
            </Text>
            <Text>Taxes including Service Fees.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Fare Refund
              </Text>{" "}
              - means a refund, under Article 10 of the Fare for your Booking.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Fare Rules
              </Text>{" "}
              - are the rules, as detailed in these Conditions of Carriage,
              published by us (as
            </Text>
            <Text>
              amended) and in effect on the date the Booking is made which
              govern the carriage of Customers
            </Text>
            <Text>
              and/or Baggage and shall include applicable Tariffs in force.
              These are available on our Website
            </Text>
            <Text>and at our offices upon request.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Flight
              </Text>{" "}
              - means a single flight operated by the Carrier from one airport
              to another airport.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Infant
              </Text>{" "}
              - means a Customer aged over eight (8) days old and less than two
              (2) years old on the
            </Text>
            <Text>date of travel.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                International Flights
              </Text>{" "}
              - mean travel on our Aircraft where a Place of Destination or
              Place of
            </Text>
            <Text>Departure are outside of Nigeria.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Place of Departure
              </Text>{" "}
              - means the airport from where your Flight will commence its
              journey.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Place of Destination
              </Text>{" "}
              - means the airport at which your Flight will end its journey.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Scheduled Arrival Time
              </Text>{" "}
              - means the time we advise you when Booking that the flight on
              Your
            </Text>
            <Text>Booking will arrive to its Place of Destination.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Scheduled Departure Time
              </Text>{" "}
              - is the time we advise you when Booking that the flight on Your
            </Text>
            <Text>Booking will depart from its Place of Departure.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Services
              </Text>{" "}
              - means the additional services you may ask us to include in your
              Booking, including
            </Text>
            <Text>
              but not limited to Preferred Seat Selection, Excess Baggage (both
              Pre-Paid and Airport), Pre-Order Meals, SMS services and Inflight
              Entertainment Vouchers.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Service Fees
              </Text>{" "}
              - means the charges incurred for each of the Services.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Special Assistance Requirements
              </Text>{" "}
              - means a Customer requiring assistance during their travel
            </Text>
            <Text>with us, as detailed in Article 9.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Stopover
              </Text>{" "}
              - means a scheduled stop on your journey at a point between the
              Place of Departure
            </Text>
            <Text>and the Place of Destination.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Tariffs
              </Text>{" "}
              - are the Service Fees, Taxes and Base Fares incurred for the
              flights and Services.
            </Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Taxes
              </Text>{" "}
              - means any fees or charges we incur from local or national
              regulatory or governmental
            </Text>
            <Text>bodies for the transport of you on our Aircraft.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Voucher
              </Text>{" "}
              - means a unique reference number which has a variable value which
              you may use as
            </Text>
            <Text>
              full or part payment towards a Booking made via our Website or by
              our customer care contact
            </Text>
            <Text>
              center. Voucher validity and terms and conditions will vary and
              will be advised at time of
            </Text>
            <Text>issuance.</Text>
            <Text>
              <Text as="span" fontWeight="bold">
                Website
              </Text>{" "}
              - means the Huriya Air website
            </Text>
          </Flex>
          <Flex direction="column" gap={3}>
            <Heading as="h2" color="heading" size="sm">
              ARTICLE 2 - APPLICABILITY
            </Heading>
            <Text>2.1 General</Text>
            <Text>
              Except as provided in paragraphs 2.2, 2.4 these Conditions of
              Carriage apply to all flights we
            </Text>
            <Text>
              operate and any liability we may have in relation to that carriage
              by air. The Conditions of
            </Text>
            <Text>
              Contract and additional information contained within the Booking
              Confirmation shall form part of
            </Text>
            <Text>these Conditions of Carriage.</Text>
            <Text>2.2 Charters</Text>
            <Text>
              If carriage is performed pursuant to a Charter Agreement, these
              Conditions of Carriage apply
            </Text>
            <Text>
              only to the extent they are incorporated by reference or otherwise
              in the Charter Agreement or
            </Text>
            <Text>the Booking.</Text>
            <Text>2.3 Overriding Law</Text>
            <Text>
              These Conditions of Carriage are applicable unless they are
              inconsistent with any tariffs or laws
            </Text>
            <Text>
              which apply to your Contract of Carriage with us in which event
              such tariffs or laws will apply.
            </Text>
            <Text>2.4 Validity of Conditions</Text>
            <Text>
              If one of these Conditions of Carriage is invalid, the other
              Conditions will remain valid.
            </Text>
          </Flex>
          <Flex direction="column" gap={3}>
            <Heading as="h2" color="heading" size="sm">
              ARTICLE 3 - BOOKINGS
            </Heading>
            <Text>3.1 General</Text>
            <Text>
              The Booking and these Conditions of Carriage (including applicable
              Tariffs) together constitute
            </Text>
            <Text>
              the terms and conditions of the contract between you and us.
            </Text>
            <Text>
              We will only provide carriage to the Customer named in the Booking
              who presents a valid
            </Text>
            <Text>
              Booking Confirmation, duly issued by us in respect of that person
              for the flight. You will also be
            </Text>
            <Text>
              required to produce positive photographic identification
              identifying you as the Customer named
            </Text>
            <Text>
              in the Booking and valid travel documentation in the form of a
              passport / National ID, and any
            </Text>
            <Text>
              visa or other documentation required, satisfying the relevant
              authorities for the journey you are
            </Text>
            <Text>undertaking.</Text>
            <Text>
              Our Fares are non-refundable, we strongly recommend that you
              ensure you have an appropriate
            </Text>
            <Text>
              level of travel insurance to cover instances where you are unable
              to use your Booking.
            </Text>
            <Text>
              We will record your Booking in our computer system. Our Authorized
              Agents will provide you with
            </Text>
            <Text>
              written confirmation of your Booking, in the case of a telephone
              reservation you will receive an
            </Text>
            <Text>
              email and / or SMS Booking Confirmation. In the case of internet
              bookings through our Website,
            </Text>
            <Text>
              a unique Booking Reference, will be displayed along with the
              Booking details on the screen upon
            </Text>
            <Text>
              completion of the transaction. You should print a copy of this for
              your records as you will be
            </Text>
            <Text>
              required to quote the Booking Reference at the time of check-in.
              You will also receive an email
            </Text>
            <Text>
              Booking Confirmation. A Booking is not made, or considered
              confirmed, until full payment of the
            </Text>
            <Text>
              Tariff and any applicable Service Fees have been received by us.
            </Text>
            <Text>3.2 Time Limits for Payment of Your Booking</Text>
            <Text>
              The Tariff, including all Service Fees, must be paid in full when
              a Booking is made. If you choose
            </Text>
            <Text>
              to pay later by bank transfer, your Booking will not be confirmed
              unless full payment has been
            </Text>
            <Text>
              received within the time limit specified by us at the time of
              booking. If a Booking has not been
            </Text>
            <Text>
              paid in full or your payment card is declined, we may cancel the
              Booking at any time without
            </Text>
            <Text>liability.</Text>
            <Text>3.3 Personal Information</Text>
            <Text>
              You recognize that your personal information has been given to us
              and that we may use the
            </Text>
            <Text>
              personal information you have provided, including information
              about how you use our services
            </Text>
            <Text>
              and facilities (&ldquo;your personal information&rdquo;), for the
              purposes of: making a Booking and issuing a
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
