import { RouterLink } from "@/components";
import useGlobalStore from "@/store";
import { Box, Button, Checkbox, Flex } from "@chakra-ui/react";

const BookingDetails = () => {
  const { setStep, step } = useGlobalStore();
  const handleNextStep = () => setStep(step + 1);

  return (
    <Box>
      Below are the luggage allowance for our products (Saver, Classic and
      Flex):
      <br />
      <br />
      Saver - 7kg cabin luggage.
      <br />
      Classic - 7kg cabin luggage + 1 item, not weighing more than 15kg check-in
      luggage.
      <br />
      Flex - 7kg cabin luggage + 1 item, not weighing more than 23kg check-in
      luggage.
      <br />
      <br />
      Please tick to accept conditions before clicking 'Continue'
      <br />
      <br />
      <Checkbox
        colorScheme="secondary"
        sx={{ "& .chakra-checkbox__control": { bgColor: "white" } }}
      >
        I have read and accept the airline’s Fare Rules and Terms and
        Conditions. I acknowledge that personal information relating to my
        booking may be accessible to government authorities, selected airlines
        and the agents to whom the airline grants system access.
      </Checkbox>
      <Flex justify="end" mt={10}>
        <RouterLink
          to="/booking/passengers"
          onClick={handleNextStep}
          w={{ base: "full", sm: "auto" }}
        >
          <Button
            px="4rem"
            py="2rem"
            colorScheme="primary"
            size="lg"
            minH="3rem"
            maxH="4rem"
            w="full"
          >
            Continue
          </Button>
        </RouterLink>
      </Flex>
    </Box>
  );
};

export default BookingDetails;
