import { Passengers } from "@/types";

export const formatPassengers = (passengers?: Passengers) => {
  if (!passengers) return "";
  const adult = passengers.adult
    ? `${passengers.adult} ${passengers.adult === 1 ? "adult" : "adults"}`
    : "";
  const child = passengers.child
    ? `, ${passengers.child} ${passengers.child === 1 ? "child" : "children"}`
    : "";
  const infant = passengers.infant
    ? `, ${passengers.infant} ${passengers.infant === 1 ? "infant" : "infants"}`
    : "";
  return `${adult}${child}${infant}`;
};
