import { Place } from "@/types";
import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { useEffect, useState } from "react";
import { Path, UseFormReturn } from "react-hook-form";
import { IconType } from "react-icons/lib";

interface Props extends BoxProps {
  icon: IconType;
  hookform: UseFormReturn<any>;
  name: Path<any>;
  initialValue?: Place;
  data: Place[];
}

const PlaceInput: React.FC<Props> = props => {
  const { hookform, icon, data, name, placeholder, initialValue, ...rest } =
    props;
  const [value, setValue] = useState<Place | null>(initialValue || null);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: name,
    options: data,
    getOptionLabel: option => `${option.name} ${option.code}`,
    value,
    onChange: (_, newValue) => setValue(newValue),
    isOptionEqualToValue: (option, value) => option.code === value.code,
  });

  useEffect(() => {
    if (value) hookform.setValue(name, value, { shouldValidate: true });
  }, [hookform, name, value]);

  const { size, ...inputProps } = getInputProps();

  return (
    <Box
      flex={{
        base: "0 0 100%",
        sm: "0 0 calc(50% - (2rem + 0.8px))",
        lg: "1",
      }}
      position="relative"
      {...rest}
    >
      <Box {...getRootProps()}>
        <InputGroup h="full">
          <InputLeftElement pointerEvents="none" h="full">
            <Icon as={icon} color="grey" fontSize="2rem" />
          </InputLeftElement>
          <Input
            ml={4}
            minH="3.5rem"
            h="full"
            variant="unstyled"
            _placeholder={{
              color: !!hookform.formState.errors[name] ? "red.300" : "grey",
            }}
            placeholder={placeholder}
            {...inputProps}
          />
        </InputGroup>
      </Box>
      {groupedOptions.length > 0 && (
        <UnorderedList
          mx={0}
          mt={4}
          position="absolute"
          bgColor="white"
          overflow="auto"
          maxH="18.75rem"
          minW={{ base: "full", md: "29rem" }}
          maxW={{ md: "30rem" }}
          shadow="md"
          zIndex="dropdown"
          {...getListboxProps()}
        >
          {groupedOptions.map((place, index) => {
            const option = place as Place;
            return (
              <ListItem
                listStyleType="none"
                p={2}
                borderRadius="8px"
                textAlign="left"
                sx={{
                  "&.Mui-focused, &.Mui-focusVisible": { bgColor: "gray.100" },
                  "&.Mui-focusVisible": { boxShadow: "outline" },
                  "&[aria-selected=true]": {
                    bgColor: "primary.500",
                    color: "white",
                  },
                  "&[aria-selected=true].Mui-focused, &[aria-selected=true].Mui-focusVisible":
                    { bgColor: "primary.500", color: "white" },
                }}
                _hover={{ cursor: "pointer", backgroundColor: "gray.200" }}
                _last={{ borderBottom: "none" }}
                {...getOptionProps({ option, index })}
              >
                <Flex
                  as="span"
                  wrap="wrap"
                  justify="space-between"
                  fontWeight="semibold"
                >
                  <Text>
                    {option.name}
                    <Text fontWeight="normal" as="span" ml={2}>
                      {option.country}
                    </Text>
                  </Text>
                  <Text>{option.code}</Text>
                </Flex>
              </ListItem>
            );
          })}
        </UnorderedList>
      )}
    </Box>
  );
};

export default PlaceInput;
