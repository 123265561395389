import { RouterLink } from "@/components";
import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  let errorMessage: string = "404";

  // if (isRouteErrorResponse(error)) {
  //   errorMessage = error.error?.message || error.statusText;
  // } else if (error instanceof Error) {
  //   errorMessage = error.message;
  // } else if (typeof error === "string") {
  //   errorMessage = error;
  // } else {
  //   console.error(error);
  //   errorMessage = "Unknown error";
  // }

  return (
    <Flex
      direction="column"
      gap={8}
      justify="center"
      align="center"
      minH="100vh"
    >
      <Heading as="h1" size="4xl">
        404!
      </Heading>
      <Text>This page does not exist</Text>
      {/* <Text fontStyle="italic">{errorMessage}</Text> */}
      <RouterLink to="/">
        <Button colorScheme="primary">Go Home</Button>
      </RouterLink>
    </Flex>
  );
}
