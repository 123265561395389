import useGlobalStore from "@/store";
import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { IoMdAirplane } from "react-icons/io";

interface Props {
  departureTime: string;
  arrivalTime: string;
}

const FlightDetailsPopover: React.FC<Props> = ({
  departureTime,
  arrivalTime,
}) => {
  const booking = useGlobalStore(state => state.booking);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Button mb={1} textDecor="underline" size="sm" variant="link">
          Direct
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody p={4}>
          <Text color="heading" fontSize="lg" mb={3}>
            Flight details
          </Text>
          <Box
            fontSize="sm"
            pl="3rem"
            mb={2}
            position="relative"
            _before={{
              content: "''",
              position: "absolute",
              top: "0.5rem",
              left: "1.75rem",
              height: "75%",
              borderLeft: "1px solid #cecece",
            }}
          >
            <Icon
              color="primary.main"
              as={IoMdAirplane}
              transform="translateY(-50%) rotate(180deg)"
              position="absolute"
              top="50%"
              left="0"
              fontSize="1.375rem"
            />
            <Text
              mb={2}
              fontWeight="medium"
              position="relative"
              _before={{
                content: "''",
                position: "absolute",
                left: "-1.5rem",
                top: "50%",
                width: "0.5rem",
                height: "0.5rem",
                borderRadius: "50%",
                bgColor: "primary.main",
                transform: "translateY(-50%)",
              }}
            >
              {departureTime}
              <Text as="span" ml={1} color="heading">
                {booking?.departureAirport.name}
                <Text as="span" ml={1} fontWeight="normal" color="gray">
                  ({booking?.departureAirport.code})
                </Text>
              </Text>
            </Text>
            <Text mb={2} color="gray">
              01:10:00{" "}
              <Text as="span" color="heading">
                Q9 314
              </Text>
            </Text>
            <Text
              mb={2}
              fontWeight="medium"
              position="relative"
              _before={{
                content: "''",
                position: "absolute",
                left: "-1.5rem",
                top: "50%",
                width: "0.5rem",
                height: "0.5rem",
                borderRadius: "50%",
                bgColor: "primary.main",
                transform: "translateY(-50%)",
              }}
            >
              {arrivalTime}
              <Text as="span" ml={1} color="heading">
                {booking?.arrivalAirport.name}
                <Text as="span" ml={1} fontWeight="normal" color="gray">
                  ({booking?.arrivalAirport.code})
                </Text>
              </Text>
            </Text>
          </Box>
          <Text fontWeight="medium">
            <Text as="span" fontWeight="normal" color="gray">
              Total travel time:{" "}
            </Text>
            1h 10m
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FlightDetailsPopover;
